// TODO: check runtime error while using import from index file
import { Roles, RolesUa } from './enums/Role';
import { activeStatusUa } from './enums/User';

import { GetUsersResponse } from '../models/UserModel';

export const userHistoryKeyDescription: { [key: string]: string } = {
    email: 'Пошта',
    firstName: 'Ім`я',
    lastName: 'Прізвище',
    middleName: 'По-батькові',
    lockedDate: 'Статус',
    roles: 'Ролі',
};

export const activeStatusSelectOptions = [
    {
        label: activeStatusUa.active,
        value: true,
    },
    {
        label: activeStatusUa.inactive,
        value: false,
    },
];

export const activeStatusFilter = [
    { text: activeStatusUa.active, value: false },
    { text: activeStatusUa.inactive, value: true },
];

export const rolesFilter = [
    { text: RolesUa.admin, value: Roles.admin },
    { text: RolesUa.controller, value: Roles.controller },
];

export const emptyData: GetUsersResponse = { count: 0, items: [] };

export const defaultSorter = 'lastName asc';

export const defaultUserQuery = {
    count: true,
};
