import React, { useCallback, useState } from 'react';
import { Button, Col, DatePicker, Row, Select, Space } from 'antd';
import { Datum, Pie } from '@ant-design/charts';
import { colorTextPrimary } from '../../../constants';
import { PerDayMerchantPercent } from '../../../redux/api/reportsApi';
import { initialMerchantPercentFilter, IPieFilter } from '../Dashboard';

interface DoublePieGraphProps {
    height: number;
    onChangePieFilter: (filter: IPieFilter) => void;
    data?: PerDayMerchantPercent;
    merchantsOptions?: { label: string; value: string }[];
}

const labelSettings = {
    type: 'inner',
    offset: '-30%',
    content: ({ percent }: any) => `${(percent * 100).toFixed(0)}%`,
    style: {
        fontSize: 14,
        textAlign: 'center',
    },
};
const DoublePieGraph = ({ height, data, merchantsOptions, onChangePieFilter }: DoublePieGraphProps) => {
    const [merchantPercentFilter, setMerchantPercentFilter] = useState<IPieFilter>(initialMerchantPercentFilter);
    const calculateColor = useCallback(
        (datum: Datum, color: any) => (datum.label !== 'Усі' ? colorTextPrimary : (color as string)),
        [],
    );
    return (
        <Row gutter={[24, 24]}>
            <Col span={24}>
                <Space>
                    <DatePicker
                        value={merchantPercentFilter.start}
                        onChange={(date) => setMerchantPercentFilter((prev) => ({ ...prev, start: date ?? undefined }))}
                        placeholder="Початкова дата"
                        disabledDate={(current) =>
                            merchantPercentFilter.end
                                ? current
                                      .startOf('day')
                                      .isBefore(merchantPercentFilter.end.subtract(3, 'month').startOf('day')) ||
                                  current.startOf('day').isAfter(merchantPercentFilter.end.startOf('day'))
                                : false
                        }
                    />
                    <DatePicker
                        value={merchantPercentFilter.end}
                        onChange={(date) => setMerchantPercentFilter((prev) => ({ ...prev, end: date ?? undefined }))}
                        placeholder="Кінцева дата"
                        disabledDate={(current) =>
                            merchantPercentFilter.start
                                ? current.startOf('day').isAfter(merchantPercentFilter.start.add(3, 'month').startOf('day')) ||
                                  current.startOf('day').isBefore(merchantPercentFilter.start.startOf('day'))
                                : false
                        }
                    />
                    <Col>
                        <div className="rules-filter__item">
                            <Select
                                className="dashboard-filter"
                                value={merchantPercentFilter.merchantName}
                                onChange={(value) =>
                                    setMerchantPercentFilter((prev) => ({ ...prev, merchantName: value ?? undefined }))
                                }
                                options={merchantsOptions}
                                id="activity"
                            />
                        </div>
                    </Col>
                    <Button
                        disabled={
                            !merchantPercentFilter.start || !merchantPercentFilter.end || !merchantPercentFilter.merchantName
                        }
                        onClick={() => onChangePieFilter(merchantPercentFilter)}>
                        Показати
                    </Button>
                </Space>
            </Col>
            <Col span={24}>
                <Row gutter={24}>
                    <Col span={12}>
                        <div className="dashboard__pie-wrapper">
                            <div className="dashboard__pie-count">Загальна кількість: {data?.totalCount}</div>
                        </div>
                        <Pie
                            color={calculateColor}
                            data={data?.countData || []}
                            height={height}
                            angleField="count"
                            colorField="label"
                            radius={0.9}
                            appendPadding={10}
                            label={labelSettings}
                        />
                    </Col>
                    <Col span={12}>
                        <div className="dashboard__pie-wrapper">
                            <div className="dashboard__pie-count">Загальна сума: {data?.totalAmount}</div>
                        </div>
                        <Pie
                            color={calculateColor}
                            data={data?.amountData || []}
                            height={height}
                            angleField="amount"
                            colorField="label"
                            radius={0.9}
                            appendPadding={10}
                            label={labelSettings}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default DoublePieGraph;
