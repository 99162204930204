import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Collapse, Row, Col, Space, Button, Table, Spin, Select, notification } from 'antd';
import { useUpdateRuleMutation } from '../../../../redux/api/rulesApi';
import { RuleModel, RuleParamModel } from '../../../../models/RulesModel';
import { TableRowSelection } from 'antd/lib/table/interface';
import EditableCell from './EditableCell';
import { useGetSystemDictionaryQuery } from '../../../../redux/api/dictionaryApi';
import { ColumnType } from 'antd/es/table/interface';
import { isArray } from '../../../../helpers/valueTypeCheck';
import { BASE_NOTIFICATION_CONFIG } from '../../../../constants/common';
import { sortDictionaryRecordByCode } from '../../../../helpers/sort';

interface DataType {
    index: number;
    code: string;
    description: string;
}

const components = {
    body: {
        cell: EditableCell,
    },
};

const dictionaryColumns: ColumnType<DataType>[] = [
    {
        title: '№ з/п',
        dataIndex: 'index',
        sorter: (a: any, b: any) => a.index - b.index,
        width: 80,
    },
    {
        title: 'Код',
        dataIndex: 'code',
        sorter: (a: any, b: any) => (a.code?.localeCompare(b.code, undefined, { numeric: true }) > 0 ? 1 : -1),
        defaultSortOrder: 'ascend',
        width: 80,
        ellipsis: true,
    },
    {
        title: 'Опис',
        dataIndex: 'description',
        sorter: (a: any, b: any) => (a.description < b.description ? -1 : a.description > b.description ? 1 : 0),
    },
];
export interface CollapsedVocabProps {
    model: RuleParamModel;
    ruleId: number;
}
const Dictionary = ({ model, ruleId }: CollapsedVocabProps) => {
    const { data: dictionary, isLoading, error } = useGetSystemDictionaryQuery(model.dictionaryName as string);
    const [updateRule, { isLoading: isUpdating }] = useUpdateRuleMutation();
    const selectWrapperRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState<Array<string>>(model.value as Array<string>);
    const [newRecords, setNewRecords] = useState<Array<string>>([]);
    const [isChanged, setChanged] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const data = useMemo(
        () =>
            dictionary
                ? sortDictionaryRecordByCode(
                      dictionary.filter((item) => isArray(values) && values.includes(item.code)),
                  )?.map((item, index) => ({
                      code: item.code,
                      description: item.description as string,
                      index: index + 1,
                  }))
                : [],
        [dictionary, values],
    );
    const options = useMemo(
        () =>
            dictionary
                ? dictionary
                      .filter((record) => isArray(values) && !values.includes(record.code))
                      .map((record) => ({ label: `${record.code} ${record.description}`, value: record.code }))
                : [],
        [dictionary, values],
    );

    const handleSave = (e: SyntheticEvent) => {
        e.stopPropagation();
        updateRule({
            id: ruleId,
            params: [
                {
                    id: model.id,
                    key: model.key,
                    value: values,
                },
            ],
        } as RuleModel)
            .unwrap()
            .then(() => {
                setChanged(false);
                notification.success({ ...BASE_NOTIFICATION_CONFIG, message: 'Довідник успішно оновлено!' });
            })
            .catch((e) => notification.error({ ...BASE_NOTIFICATION_CONFIG, message: e.data.message }));
    };
    const handleDelete = (e: SyntheticEvent) => {
        e.stopPropagation();
        setValues(values.filter((item) => !selectedRowKeys.includes(item)));
        setSelectedRowKeys([]);
        setChanged(true);
    };
    const onSelectChange = useCallback((newSelectedRowKeys: React.Key[]) => setSelectedRowKeys(newSelectedRowKeys), []);
    const handleAddRecord = () => {
        setValues((prev) => [...prev, ...newRecords]);
        setNewRecords([]);
        setChanged(true);
    };

    const rowSelection: TableRowSelection<DataType> = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    useEffect(() => {
        if (error) {
            notification.error({ ...BASE_NOTIFICATION_CONFIG, message: (error as any)?.data?.message });
        }
    }, [error]);

    return (
        <Spin spinning={isLoading || isUpdating}>
            <Collapse onChange={(values) => setOpen(!!(values as Array<string>)?.length)}>
                <Collapse.Panel
                    className="rules-collapse__vocab_panel"
                    header={
                        <Row justify="space-between">
                            <Col>
                                <b>{model.label}</b>
                            </Col>
                            <Col>
                                <Space>
                                    <Button
                                        disabled={!open || isLoading || !selectedRowKeys.length || isUpdating}
                                        type="primary"
                                        danger
                                        onClick={handleDelete}>
                                        Видалити
                                    </Button>
                                    <Button
                                        disabled={!open || isLoading || isUpdating || !isChanged}
                                        type="primary"
                                        onClick={handleSave}>
                                        Зберегти зміни
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    }
                    key="1">
                    <div className="dictionary-select" ref={selectWrapperRef}>
                        <Select
                            value={newRecords}
                            mode="multiple"
                            placeholder="Додати значення (код)"
                            onChange={(values) => setNewRecords(values)}
                            className="dictionary-select__input"
                            filterOption={(value, option) =>
                                !!option?.label.toLowerCase().includes(value.toLowerCase())
                            }
                            options={options}
                            getPopupContainer={() => selectWrapperRef.current as HTMLDivElement}
                        />
                        <Button onClick={handleAddRecord}>Додати</Button>
                    </div>
                    <Table
                        size="small"
                        components={components}
                        rowClassName={() => 'editable-row'}
                        rowSelection={rowSelection}
                        rowKey="code"
                        bordered={false}
                        dataSource={data}
                        columns={dictionaryColumns}
                    />
                </Collapse.Panel>
            </Collapse>
        </Spin>
    );
};

export default Dictionary;
