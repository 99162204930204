import { FC } from 'react';
import { Form, Input, Row, Select } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { activeStatusSelectOptions } from '../../../constants/User';
import { useGetRolesQuery } from '../../../redux/api/rolesApi';

interface UserDataFieldsProps {
    disabledLogin?: boolean;
    disableTempPass?: boolean;
}

const UserDataFields: FC<UserDataFieldsProps> = ({ disabledLogin, disableTempPass }) => {
    const { data: roles = [], isLoading, isFetching } = useGetRolesQuery();

    const rolesOptions: DefaultOptionType[] = roles.map((role) => ({
        value: role.name,
        label: role.description,
        disabled: false,
    }));

    return (
        <>
            <Form.Item label="Логін" name="login" rules={[{ required: true }]}>
                <Input placeholder="Логін" disabled={disabledLogin} />
            </Form.Item>
            <Form.Item
                label="Email"
                name="email"
                rules={[
                    { required: true },
                    {
                        type: 'email',
                        message: 'Введіть корректний адрес email!',
                    },
                ]}>
                <Input placeholder="Логін" />
            </Form.Item>
            <Form.Item label="Прізвище" name="lastName" rules={[{ required: true }]}>
                <Input placeholder="Прізвище" />
            </Form.Item>
            <Form.Item label="Імʼя" name="firstName" rules={[{ required: true }]}>
                <Input placeholder="Ім’я" />
            </Form.Item>
            <Form.Item label="По батькові" name="middleName">
                <Input placeholder="По батькові" autoComplete="additional-name" />
            </Form.Item>
            <Form.Item label="Ролі" name="roles" rules={[{ required: true }]}>
                <Select
                    mode="multiple"
                    placeholder="Оберіть ролі"
                    style={{ width: '100%' }}
                    options={rolesOptions}
                    loading={isLoading || isFetching}
                />
            </Form.Item>
            <Form.Item label="Статус" name="activeStatus" rules={[{ required: true }]} initialValue={!disabledLogin}>
                <Select
                    placeholder="Оберіть статус"
                    style={{ width: '100%' }}
                    options={activeStatusSelectOptions}
                    loading={isLoading || isFetching}
                />
            </Form.Item>
            {!disableTempPass && (
                <Form.Item name="password" label="Тимчасовий пароль" rules={[{ required: true }]}>
                    <Input.Password autoComplete="new-password" />
                </Form.Item>
            )}
            <Row style={{ marginBottom: '1.5rem' }} />
        </>
    );
};

export default UserDataFields;
