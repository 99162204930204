import { NotificationPlacement } from './enums';
import {ActivityTypes} from "./enums/ActivitiTypes";

export const BOOLEAN = 'boolean';
export const NUMBER = 'number';
export const STRING = 'string';
export const DICTIONARY = 'dictionary';

export const COLOR_GREEN = 'green';
export const COLOR_RED = 'red';
export const COLOR_PURPLE = 'purple';
export const COLOR_ORANGE = 'orange';
export const COLOR_BLUE = 'blue';
export const COLOR_DEFAULT = 'default';

export const HISTORY_NO_DATA = 'Дані відсутні';

export const DISABLED_WHITESPACE = 'Поле не може містити лише пробіли';

export const BASE_NOTIFICATION_CONFIG = {
    placement: NotificationPlacement.TOP,
    duration: 2,
};

export const BASE_PAGINATION_CONFIG_20 = {
    current: 1,
    pageSize: 20,
    showSizeChanger: true,
};

export const ACTIVITY_OPTIONS = [
    { label: 'Всі', value: ActivityTypes.all },
    { label: 'Активні', value: ActivityTypes.active },
    { label: 'Неактивні', value: ActivityTypes.disabled },
];