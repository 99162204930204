import { Divider } from 'antd';
import { FC } from 'react';

interface IProps {
    value: string;
}

const HistoryRow: FC<IProps> = ({ value }) => {
    return (
        <>
            <div className="history__cell" title={value}>
                {!value?.toString().replace(/\s/g, '') ? <br /> : value}
            </div>
            <Divider style={{ margin: 0 }} />
        </>
    );
};

export default HistoryRow;
