import { Button, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { HistoryOutlined } from '@ant-design/icons';

import { ROLE_PROPERTY_NAME, activeStatusFilter, rolesFilter } from '../../../constants';
import { Roles, Colors } from '../../../constants/enums';
import { sortArrayOfObjectsByProperty, activeUserCheck } from '../../../helpers';
import { RoleModel } from '../../../models/RoleModel';
import { HandleHistoryClick, LockedDate, UserModel } from '../../../models/UserModel';

const getRoleTags = (roles: RoleModel[]) => (
    <>
        {sortArrayOfObjectsByProperty(roles, ROLE_PROPERTY_NAME).map((role) => (
            <Tag
                key={role.description}
                className="user-roles__tag"
                color={(() => {
                    switch (role.name) {
                        case Roles.admin:
                            return Colors.red;
                        default:
                            return Colors.orange;
                    }
                })()}>
                {role.description}
            </Tag>
        ))}
    </>
);

const getStatusTag = (lockedDate: LockedDate) => (
    <Tag className="user-is_active" color={lockedDate ? Colors.red : Colors.green}>
        {activeUserCheck(lockedDate)}
    </Tag>
);

const getColumns = (handleHistoryClick: HandleHistoryClick): ColumnsType<UserModel> => [
    {
        title: 'ПІБ',
        dataIndex: 'lastName',
        key: 'lastName',
        render: (value, record) => `${value} ${record.firstName} ${record.middleName || ''}`,
        width: '160px',
        ellipsis: true,
        sorter: true,
    },
    {
        title: 'Логін',
        dataIndex: 'email',
        key: 'email',
        width: '240px',
        sorter: true,
    },
    {
        title: 'Ролі',
        dataIndex: 'roles',
        key: 'roles',
        render: (roles: RoleModel[]) => getRoleTags(roles),
        ellipsis: true,
        width: '200px',
        filters: rolesFilter,
    },
    {
        title: 'Статус',
        dataIndex: 'lockedDate',
        key: 'lockedDate',
        width: '80px',
        render: (lockedDate: LockedDate) => getStatusTag(lockedDate),
        filters: activeStatusFilter,
        sorter: true,
    },
    {
        title: 'Історія',
        dataIndex: 'id',
        key: 'id',
        width: '50px',
        render: (id) => (
            <Button size="small" icon={<HistoryOutlined />} onClick={(e) => handleHistoryClick(id, e)}></Button>
        ),
    },
];

export default getColumns;
