import React, { FC, ReactNode, useEffect, useRef } from 'react';
import { Tooltip } from 'antd';
interface ResizableColumnProps {
    colIndex: number;
    children: ReactNode;
    storageKey: string;
    minWidth?: number;
}

const setWidthToStorage = (index: number, width: number, key: string) => {
    const cashed = window.localStorage.getItem(key);
    let sizes = cashed ? JSON.parse(cashed) : null;
    if (!sizes) {
        sizes = {};
    }
    sizes[index] = width;
    window.localStorage.setItem(key, JSON.stringify(sizes));
};
const ResizableColumn: FC<ResizableColumnProps> = ({ colIndex, children, storageKey, minWidth = 50 }) => {
    const root = useRef(null);
    const mouseX = useRef(0);
    const startPos = useRef(0);
    const startWidthCol = useRef(0);
    const startWidthTable = useRef(0);
    const dragging = useRef(false);
    const table = useRef<HTMLTableElement | null>(null);
    const col = useRef<HTMLTableElement | null>(null);
    const bodyCol = useRef<HTMLTableElement | null>(null);

    const startDrag = (event: any) => {
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', endDrag);
        dragging.current = true;
        startPos.current = event.screenX;
        startWidthCol.current = 0;
        startWidthTable.current = 0;

        if (col.current && table.current) {
            startWidthCol.current = col.current.getBoundingClientRect().width;
            startWidthTable.current = table.current.getBoundingClientRect().width;
        }
    };
    const endDrag = () => {
        dragging.current = false;
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', endDrag);
        setWidthToStorage(colIndex, col.current?.offsetWidth as number, storageKey);
    };
    const onMouseMove = (event: MouseEvent) => {
        mouseX.current = event.screenX;
        const moveDiff = startPos.current - mouseX.current;
        const newWidthCol = startWidthCol.current - moveDiff;
        const newWidthTable = startWidthTable.current - moveDiff;

        if (newWidthCol && newWidthCol >= minWidth && col.current && table.current) {
            col.current.style.width = newWidthCol + 'px';
            table.current.style.width = newWidthTable + 'px';

            if (bodyCol.current) {
                bodyCol.current.style.width = newWidthCol + 'px';
            }
        }
    };

    useEffect(() => {
        let td: HTMLTableElement;
        let div: HTMLDivElement;
        if (root.current) {
            td = root.current;
            do {
                td = td.parentNode as HTMLTableElement;
            } while (td.tagName !== 'TH');
            if (td) {
                div = document.createElement('div');
                div.classList.add('column-resizer__cursor');
                div.onclick = (e) => e.stopPropagation();
                div.onmousedown = startDrag;
                td.appendChild(div);
            }
            table.current = root.current;
            do {
                table.current = table.current?.parentNode as HTMLTableElement;
            } while (table.current?.tagName !== 'TABLE');
            col.current = (table.current?.firstChild as HTMLTableElement)?.children?.[colIndex] as HTMLTableElement;
            bodyCol.current = (document.querySelector('.ant-table-body table colgroup') as HTMLTableElement)
                ?.children?.[colIndex] as HTMLTableElement;
        }
        return () => {
            td.removeChild(div);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [root.current]);

    return (
        <Tooltip color={'grey'} title={children} arrow={false} placement={'bottomLeft'}>
            <div className="column-resizer alerts-table_header" ref={root}>
                {children}
            </div>
        </Tooltip>
    );
};

export default ResizableColumn;
