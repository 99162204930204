import { createApi } from '@reduxjs/toolkit/query/react';

import customBaseQuery from './common/customBaseQuery';
import dayjs from 'dayjs';
import { DATE_FORMAT, TIME_FORMAT } from '../../constants';
import { getDashboardFilter } from '../../helpers/getDashboardFilter';
import { IFilterDashboard } from '../../pages/dashboard/Dashboard';

interface PerHourCountResponse {
    hour: string;
    count: string;
}
export interface PerDayCountResponse {
    day: string;
    count: string;
}

export interface PerDayAvgAmountResponse {
    day: string;
    amount: number;
}

export interface PerDayPercent {
    data: { count: number; percent: number; label: string }[];
    total: number;
}

export interface PerDayMerchantPercent {
    countData: { count: number; percent: number; label: string }[];
    amountData: { amount: number; percent: number; label: string }[];
    totalCount: number;
    totalAmount: number;
}

interface AmountPerHour {
    hour: string;
    amount: number;
}
export const reportsApi = createApi({
    reducerPath: 'reportsApi',
    baseQuery: customBaseQuery,
    endpoints: (builder) => ({
        getPerHourCountChart: builder.query<PerHourCountResponse[], { filterDashboard: IFilterDashboard }>({
            query({ filterDashboard }) {
                const filter = getDashboardFilter(filterDashboard);
                return {
                    url: `process/dashboard/count-per-hour?${filter}`,
                    method: 'GET',
                };
            },
            transformResponse(response: PerHourCountResponse[], _meta, _arg): PerHourCountResponse[] {
                return response.map((item) => ({
                    ...item,
                    hour: dayjs(item.hour).format(TIME_FORMAT),
                }));
            },
        }),
        getPerDayCountChart: builder.query<PerDayCountResponse[],{ startDate: string; endDate: string; filterDashboard: IFilterDashboard }>({
            query({ startDate, endDate, filterDashboard }) {
                const filter = getDashboardFilter(filterDashboard);
                return {
                    url: `process/dashboard/count-per-days?startDate=${startDate}&endDate=${endDate}&${filter}`,
                    method: 'GET',
                };
            },
            transformResponse(response: PerDayCountResponse[], _meta, _arg): PerDayCountResponse[] {
                return response.map((item) => ({
                    ...item,
                    day: dayjs(item.day).format(DATE_FORMAT),
                }));
            },
        }),
        getPerDayPercentChart: builder.query<PerDayPercent, { startDate: string; endDate: string; filterDashboard: IFilterDashboard }>({
            query({ startDate, endDate, filterDashboard }) {
                const filter = getDashboardFilter(filterDashboard);
                return {
                    url: `process/dashboard/percent-for-day?startDate=${startDate}&endDate=${endDate}&${filter}`,
                    method: 'GET',
                };
            },
        }),
        getPerDayPercentForMerchantChart: builder.query<PerDayMerchantPercent, { startDate: string; endDate: string; merchantName: string }>({
            query({ startDate, endDate, merchantName }) {
                return {
                    url: `process/dashboard/percent-merchant-for-day?startDate=${startDate}&endDate=${endDate}&merchantName=${merchantName}`,
                    method: 'GET',
                };
            },
        }),
        getAmountPerHourChart: builder.query<AmountPerHour[], { date: string; filterDashboard: IFilterDashboard }>({
            query({ date, filterDashboard }) {
                const filter = getDashboardFilter(filterDashboard);
                return {
                    url: `process/dashboard/amount-per-hour?date=${date}&${filter}`,
                    method: 'GET',
                };
            },
            transformResponse(response: AmountPerHour[], _meta, _arg): AmountPerHour[] {
                return response.map((item) => ({
                    ...item,
                    hour: dayjs(item.hour).format(TIME_FORMAT),
                }));
            },
        }),
        getAvgAmountPerHourChart: builder.query<AmountPerHour[], { date: string; filterDashboard: IFilterDashboard }>({
            query({ date, filterDashboard }) {
                const filter = getDashboardFilter(filterDashboard);
                return {
                    url: `process/dashboard/avg-amount-per-hour?date=${date}&${filter}`,
                    method: 'GET',
                };
            },
            transformResponse(response: AmountPerHour[], _meta, _arg): AmountPerHour[] {
                return response.map((item) => ({
                    ...item,
                    hour: dayjs(item.hour).format(TIME_FORMAT),
                }));
            },
        }),
        getPerDayAvgAmountChart: builder.query<PerDayAvgAmountResponse[], { startDate: string; endDate: string; filterDashboard: IFilterDashboard }>({
            query({ startDate, endDate, filterDashboard }) {
                const filter = getDashboardFilter(filterDashboard);
                return {
                    url: `process/dashboard/avg-amount-per-days?startDate=${startDate}&endDate=${endDate}&${filter}`,
                    method: 'GET',
                };
            },
            transformResponse(response: PerDayAvgAmountResponse[], _meta, _arg): PerDayAvgAmountResponse[] {
                return response.map((item) => ({
                    ...item,
                    day: dayjs(item.day).format(DATE_FORMAT),
                }));
            },
        }),
    }),
});

export const {
    useGetPerHourCountChartQuery,
    useGetPerDayCountChartQuery,
    useGetPerDayPercentChartQuery,
    useGetAmountPerHourChartQuery,
    useGetAvgAmountPerHourChartQuery,
    useGetPerDayAvgAmountChartQuery,
    useGetPerDayPercentForMerchantChartQuery,
} = reportsApi;
