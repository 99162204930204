import React from 'react';
import { Col, Form, Input, Row } from 'antd';
import { ObjProperties, RuleParamModel } from '../../../models/RulesModel';
import { DISABLED_WHITESPACE } from '../../../constants/common';

interface IProps {
    value?: object;
    paramIndex: number;
    param: RuleParamModel;
}
const ParameterObject = ({ value, paramIndex, param }: IProps) => {
    const fields = value ? Object.keys(value) : [];
    return (
        <Row gutter={[16, 16]}>
            {fields.map((field) => (
                <Col key={field}>
                    <Row>
                        <Col>{(param.properties as ObjProperties)?.[field]?.label}:&nbsp;</Col>
                        <Col>
                            <Form.Item
                                key={field}
                                name={['params', paramIndex, 'value', field]}
                                rules={[
                                    { required: true },
                                    { whitespace: true, message: DISABLED_WHITESPACE },
                                    {
                                        min: (param.properties as ObjProperties)?.[field]?.minLength,
                                        max: (param.properties as ObjProperties)?.[field]?.maxLength,
                                        // eslint-disable-next-line no-template-curly-in-string
                                        message: 'довжина має бути від ${min} до ${max} символів',
                                    },
                                ]}>
                                <Input style={{ width: '100px' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            ))}
        </Row>
    );
};

export default ParameterObject;
