import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Col, Row, Table, TablePaginationConfig, TableProps } from 'antd';
import { QueryOptions } from 'odata-query';

import UserFilters from './components/UserFilters';
import UserHistory from './components/UserHistory';
import getColumns from './components/getColumnUser';

import Modal from '../../components/common/Modal';
import closeWithoutSaveConfirmModal from '../../components/common/closeWithoutSaveConfirmModal';
import CreateUserForm from '../../components/form/user-form/CreateUserForm';
import EditUserForm from '../../components/form/user-form/UpdateUserForm';
import { BASE_PAGINATION_CONFIG_20, defaultSorter, defaultUserQuery, emptyData } from '../../constants';
import { getSorterQuery, getTopSkip } from '../../helpers';
import { getUserFilters, getUserFiltersFromFormValues } from '../../helpers/odataFilters';
import { useModal, useBoolean } from '../../hooks';
import { HandleHistoryClick, UserFiltersModel, UserModel } from '../../models/UserModel';
import { useGetUsersQuery } from '../../redux/api/userApi';

const Users = () => {
    const [selectedUser, setSelectedUser] = useState<UserModel>();

    const [createUserModal, openCreateUserModal, closeCreateUserModal] = useModal();
    const [editUserModal, openEditUserModal, closeEditUserModal] = useModal();
    const [historyPage, openHistoryPage, closeHistoryPage] = useModal();

    const [isEdited, setIsEditedTrue, setIsEditedFalse] = useBoolean();
    const closeConfirmation = (closeModal: () => void) =>
        isEdited ? () => closeWithoutSaveConfirmModal(closeModal) : closeModal;

    const [pagination, setPagination] = useState<TablePaginationConfig>(BASE_PAGINATION_CONFIG_20);
    const [filters, setFilters] = useState<any[]>([]);
    const [sorter, setSorter] = useState<QueryOptions<UserModel>['orderBy']>(defaultSorter);

    const [search, setSearch] = useState<UserFiltersModel>({});
    const handleSearch = useCallback((filter: string) => {
        setPagination((currentPagination) => ({ ...currentPagination, current: 1 }));
        setSearch(getUserFiltersFromFormValues(filter));
    }, []);

    const queryData = useMemo<Partial<QueryOptions<UserModel>>>(
        () => ({
            ...defaultUserQuery,
            ...getTopSkip(pagination.pageSize, pagination.current),
            filter: [...filters, search],
            orderBy: sorter,
        }),
        [pagination, filters, sorter, search],
    );

    const handleTableChange: TableProps<UserModel>['onChange'] = (newPagination, filters, sorter) => {
        setFilters(getUserFilters(filters));
        setPagination(newPagination);
        setSorter(getSorterQuery(sorter) || defaultSorter);
    };

    const handleHistoryClick: HandleHistoryClick = (id, e) => {
        e.stopPropagation();

        const result = users.find((user) => user.id === id);
        if (!result) return;

        setSelectedUser(result);
        openHistoryPage();
    };

    const rowCb: TableProps<UserModel>['onRow'] = useCallback(
        (record: UserModel) => ({
            onClick: () => {
                setSelectedUser(record);
                openEditUserModal();
            },
        }),
        [openEditUserModal],
    );

    const { data: { count, items: users } = emptyData, isLoading, isFetching } = useGetUsersQuery(queryData);

    useEffect(() => setPagination((currentPagination) => ({ ...currentPagination, total: count })), [count]);

    return (
        <>
            <Modal
                destroyOnClose
                title="Створення користувача"
                onCancel={closeConfirmation(() => {
                    closeCreateUserModal();
                    setIsEditedFalse();
                })}
                open={createUserModal}
                footer={null}>
                <CreateUserForm onSuccess={closeCreateUserModal} setIsEditedTrue={setIsEditedTrue} />
            </Modal>

            <Modal
                destroyOnClose
                title={`Редагування користувача "${selectedUser?.lastName} ${selectedUser?.firstName}"`}
                onCancel={closeConfirmation(() => {
                    closeEditUserModal();
                    setIsEditedFalse();
                })}
                open={editUserModal}
                footer={null}>
                {selectedUser && (
                    <EditUserForm
                        user={selectedUser}
                        onSuccess={closeEditUserModal}
                        setIsEditedTrue={setIsEditedTrue}
                    />
                )}
            </Modal>

            {historyPage && selectedUser && <UserHistory user={selectedUser} closePage={closeHistoryPage} />}

            {!historyPage && (
                <Row>
                    <Col span={12}>
                        <UserFilters setFilters={handleSearch} />
                    </Col>
                    <Col span={12}>
                        <Row justify="end">
                            <Button type="primary" onClick={openCreateUserModal}>
                                Створити користувача
                            </Button>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Table<UserModel>
                            size="small"
                            scroll={{ x: 600, y: 'calc(100vh - 375px)' }}
                            rowKey="id"
                            loading={isLoading || isFetching}
                            columns={getColumns(handleHistoryClick)}
                            dataSource={users}
                            pagination={pagination}
                            onChange={handleTableChange}
                            onRow={rowCb}
                        />
                    </Col>
                </Row>
            )}
        </>
    );
};

export default Users;
