import React, {useCallback, useState} from 'react';
import { Button, Col, DatePicker, Row, Space } from 'antd';
import { Datum, Pie } from '@ant-design/charts';
import { colorTextPrimary } from '../../../constants';
import { PerDayPercent } from '../../../redux/api/reportsApi';
import {initialPeriodOneDay, IPeriod} from "../Dashboard";

interface PieGraphProps {
    height: number;
    onChangePeriod: (period: IPeriod) => void;
    data?: PerDayPercent;
}

const labelSettings = {
    type: 'inner',
    offset: '-30%',
    content: ({ percent }: any) => `${(percent * 100).toFixed(0)}%`,
    style: {
        fontSize: 14,
        textAlign: 'center',
    },
}
const PieGraph = ({ height, data, onChangePeriod }: PieGraphProps) => {
    const [period, setPeriod] = useState<IPeriod>(initialPeriodOneDay);
    const calculateColor = useCallback(
        (datum: Datum, color: any) => (datum.label === 'Відхилено' ? colorTextPrimary : (color as string)),
        [],
    );
    return (
        <Row gutter={[24, 24]}>
            <Col span={24}>
                <Space>
                    <DatePicker
                        value={period.start}
                        onChange={(date) => setPeriod((prev) => ({ ...prev, start: date ?? undefined }))}
                        placeholder="Початкова дата"
                        disabledDate={(current) =>
                            period.end
                                ? current.startOf('day').isBefore(period.end.subtract(3, 'month').startOf('day')) ||
                                  current.startOf('day').isAfter(period.end.startOf('day'))
                                : false
                        }
                    />
                    <DatePicker
                        value={period.end}
                        onChange={(date) => setPeriod((prev) => ({ ...prev, end: date ?? undefined }))}
                        placeholder="Кінцева дата"
                        disabledDate={(current) =>
                            period.start
                                ? current.startOf('day').isAfter(period.start.add(3, 'month').startOf('day')) ||
                                  current.startOf('day').isBefore(period.start.startOf('day'))
                                : false
                        }
                    />
                    <Button disabled={!period.start || !period.end} onClick={() => onChangePeriod(period)}>
                        Показати
                    </Button>
                </Space>
            </Col>
            <Col span={24}>
                <div className="dashboard__pie-wrapper">
                    <div className="dashboard__pie-count">Всього: {data?.total}</div>
                </div>
                <Pie
                    color={calculateColor}
                    data={data?.data || []}
                    height={height}
                    angleField="count"
                    colorField="label"
                    radius={0.9}
                    appendPadding={10}
                    label={labelSettings}
                />
            </Col>
        </Row>
    );
};

export default PieGraph;
