import React, { useEffect } from 'react';
import { useUpdateLicenseMutation } from '../redux/api/dictionaryApi';
import { useParams } from 'react-router-dom';
import { notification, Spin } from 'antd';
import { BASE_NOTIFICATION_CONFIG } from '../constants';
import { useNavigate } from 'react-router-dom';

const License = () => {
    const { code } = useParams();
    const navigate = useNavigate();
    const [update, { isSuccess, error }] = useUpdateLicenseMutation();

    useEffect(() => {
        if (code) {
            update(code);
        } else {
            navigate('/');
        }
    }, [code, navigate, update]);

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                ...BASE_NOTIFICATION_CONFIG,
                message: `Ліцензію успішно продовжено`,
            });
            navigate('/');
        }
    }, [isSuccess, navigate]);

    useEffect(() => {
        if (error) {
            notification.error({
                ...BASE_NOTIFICATION_CONFIG,
                message: (error as any)?.data?.message,
            });
            navigate('/');
        }
    }, [error, navigate]);

    return (
        <div className="license__root">
            <Spin tip="Loading" size="large" />
        </div>
    );
};

export default License;
