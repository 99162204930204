import { Typography } from 'antd';
import pjson from '../../../package.json';
import { getApiVersion } from '../../redux/features/authSlice';
import { useAppSelector } from '../../redux/hooks';

const { Text } = Typography;

const Version = () => {
    const apiVersion = useAppSelector(getApiVersion);
    return (
        <Text type="secondary" code>
            v.{pjson.version}{apiVersion && `, API v.${apiVersion}`}
        </Text>
    );
};

export default Version;
