import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Col, Form, FormProps, notification, Row, Select, Spin, Tabs } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import Header from './Header';
import History from './History';
import Params from './Params';
import Dictionary from './vocabulary/Dictionary';

import {
    RULES_OPTIONS_TYPE_EDIT,
    RULES_OPTIONS_TYPE_VIEW,
    ACTIVITY_ALL,
    ACTIVITY_ACTIVE,
    ACTIVITY_DISABLE,
    RULES_TYPE_WARNING,
} from '../../../constants/Rules';
import { BASE_NOTIFICATION_CONFIG } from '../../../constants/common';
import { numberToInputString } from '../../../helpers/numberToInputString';
import { OptionsType, RuleModel, RuleParamModel } from '../../../models/RulesModel';
import { useGetRuleQuery, useUpdateRuleMutation } from '../../../redux/api/rulesApi';
import TabsSelect from '../../common/TabsSelect';
import { EmployeeModel } from '../../../models/UserModel';

interface ContentProps {
    type: 'warning' | 'online';
    collection: Array<RuleModel>;
    users: EmployeeModel[];
}

const activityOptions = [
    { label: 'Всі', value: ACTIVITY_ALL },
    { label: 'Активні', value: ACTIVITY_ACTIVE },
    { label: 'Неактивні', value: ACTIVITY_DISABLE },
];

const Content: FC<ContentProps> = ({ collection, users, type }) => {
    const [updateRule, { isLoading: isUpdating }] = useUpdateRuleMutation();
    const [selectedId, setSelectedId] = useState<string>();
    const [optionType, setOptionType] = useState<OptionsType>(RULES_OPTIONS_TYPE_VIEW);
    const [showHistory, setShowHistory] = useState(false);
    const [form] = useForm<RuleModel>();
    const [isEdited, setIsEdited] = useState(false);
    const [activity, setActivity] = useState(ACTIVITY_ALL);
    const { data: selectedRule, error, isLoading, isFetching } = useGetRuleQuery(selectedId, { skip: !selectedId });
    const isWarningType = type === RULES_TYPE_WARNING;
    const rules = useMemo(
        () =>
            collection.filter((rule) =>
                activity === ACTIVITY_ALL ? rule : activity === ACTIVITY_ACTIVE ? rule.isActive : !rule.isActive,
            ),
        [collection, activity],
    );
    const toggleHistoryPage = useCallback(() => setShowHistory((prev) => !prev), []);
    const toggleEditMode = useCallback(
        () =>
            setOptionType((prev) =>
                prev === RULES_OPTIONS_TYPE_VIEW ? RULES_OPTIONS_TYPE_EDIT : RULES_OPTIONS_TYPE_VIEW,
            ),
        [],
    );
    const handleTabClick = useCallback((activeKey: string) => {
        setSelectedId(activeKey);
        setShowHistory(false);
    }, []);
    const onFinish: FormProps<RuleModel>['onFinish'] = (values) => {
        (values.params as RuleParamModel[])?.forEach((param) => {
            if (param.type?.code === 'number' && typeof param.value === 'string') {
                // значение из инпута стринга, преобразовываем в number для сохранения в бд
                param.value = +(param.value as string).replace(',', '.');
            }
        });
        if (values.score) {
            values.score = +values.score;
        }

        values.assignedUsers = (isWarningType
            ? values.assignedUsers.map((id) => ({ id }))
            : []) as never as EmployeeModel[];

        updateRule(values)
            .unwrap()
            .then(() => {
                toggleEditMode();
                setIsEdited(false);
                notification.success({ ...BASE_NOTIFICATION_CONFIG, message: 'Дані успішно оновлено' });
            })
            .catch((error) => notification.error({ ...BASE_NOTIFICATION_CONFIG, message: error.data.message }));
    };
    const discardChanges = () => {
        if (selectedRule) {
            const initialData = { ...selectedRule, assignedUsers: selectedRule.assignedUsers.map((user) => user.id) };
            initialData.params = (initialData.params as RuleParamModel[])?.map((param: any) => {
                if (param.type?.code === 'number') {
                    return { ...param, value: numberToInputString(param.value as number, param.fraction) };
                }
                return param;
            });
            form.setFieldsValue(initialData as any);
            setIsEdited(false);
        }
    };

    useEffect(() => {
        if (selectedRule) {
            const initialData = { ...selectedRule, assignedUsers: selectedRule.assignedUsers.map((user) => user.id) };
            initialData.params = (initialData.params as RuleParamModel[])?.map((param: any) => {
                if (param.type?.code === 'number') {
                    return { ...param, value: numberToInputString(param.value as number, param.fraction) };
                }
                return param;
            });
            form.setFieldsValue({ ...initialData, score: selectedRule.score?.toString() as string } as any);
        }
    }, [form, selectedRule]);

    useEffect(() => {
        if (rules.length && !selectedId) {
            setSelectedId(rules[0].id?.toString());
        }
    }, [rules, selectedId]);

    useEffect(() => {
        if (activity !== ACTIVITY_ALL && rules[0]) {
            setSelectedId(rules[0].id?.toString());
        }
    }, [activity, rules]);

    useEffect(() => {
        if (error) {
            notification.error({ ...BASE_NOTIFICATION_CONFIG, message: (error as any)?.data?.message });
        }
    }, [error]);

    return (
        <>
            <Row gutter={16}>
                <Col>
                    <div className="rules-filter__item">
                        <label htmlFor="shortName">Пошук: коротка назва</label>
                        <TabsSelect
                            activeTab={selectedId}
                            onChangeSelect={handleTabClick}
                            options={rules?.map((item) => ({ label: item.shortName, value: `${item.id}` }))}
                            id="shortName"
                            disabled={optionType === RULES_OPTIONS_TYPE_EDIT}
                        />
                    </div>
                </Col>
                <Col>
                    <div className="rules-filter__item">
                        <label htmlFor="activity">Фільтр активності критеріїв</label>
                        <Select
                            className="activity-type"
                            value={activity}
                            onChange={(value) => setActivity(value)}
                            options={activityOptions}
                            id="activity"
                            disabled={optionType === RULES_OPTIONS_TYPE_EDIT}
                        />
                    </div>
                </Col>
            </Row>
            <Tabs
                activeKey={selectedId}
                onChange={handleTabClick}
                defaultActiveKey="1"
                tabPosition="left"
                items={rules?.map((rule) => {
                    return {
                        label: (
                            <span className={`antd__tab ${rule.isActive === 0 ? 'rules-tabs__label_inactive' : ''}`}>
                                {rule.shortName}
                            </span>
                        ),
                        key: `${rule.id}`,
                        disabled: optionType === RULES_OPTIONS_TYPE_EDIT,
                        children: (
                            <div className="tab_children">
                                <Spin spinning={isLoading || isFetching || isUpdating}>
                                    <Form
                                        form={form}
                                        validateMessages={{
                                            required: 'Обов’язкове поле!',
                                        }}
                                        onFinish={onFinish}
                                        validateTrigger="onSubmit"
                                        onValuesChange={() => setIsEdited(true)}>
                                        {selectedRule ? (
                                            showHistory ? (
                                                <History selectedRule={selectedRule} onClose={toggleHistoryPage} />
                                            ) : (
                                                <div className="rules-content">
                                                    <Header
                                                        selectedRule={selectedRule}
                                                        onOpenHistory={toggleHistoryPage}
                                                        toggleEditMode={toggleEditMode}
                                                        optionType={optionType}
                                                        isEdited={isEdited}
                                                        onCancel={discardChanges}
                                                    />
                                                    <Params
                                                        users={users}
                                                        selectedRule={selectedRule}
                                                        optionType={optionType}
                                                        withAssignedUsers={type === RULES_TYPE_WARNING}
                                                    />
                                                    {selectedRule?.catalog?.map((param) => (
                                                        <Dictionary
                                                            key={param.id}
                                                            model={param}
                                                            ruleId={selectedRule.id}
                                                        />
                                                    ))}
                                                </div>
                                            )
                                        ) : null}
                                    </Form>
                                </Spin>
                            </div>
                        ),
                    };
                })}
            />
        </>
    );
};

export default Content;
