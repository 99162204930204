import { ApartmentTypes, BuildingTypes, CorpsTypes, LocalityTypes, StreetTypes } from './enums';
import { BoolFromInt } from './enums/BoolFromInt';

export const systemDictTranslation = {
    ...LocalityTypes,
    ...StreetTypes,
    ...BuildingTypes,
    ...CorpsTypes,
    ...ApartmentTypes,
};

export const INCLUDE_LOCALE = {
    [BoolFromInt.TRUE]: 'Включено',
    [BoolFromInt.FALSE]: 'Виключено',
};

export const INCLUDE_OPTIONS = [
    { text: INCLUDE_LOCALE[BoolFromInt.TRUE], value: BoolFromInt.TRUE },
    { text: INCLUDE_LOCALE[BoolFromInt.FALSE], value: BoolFromInt.FALSE },
];

