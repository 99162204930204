import React, { useState } from 'react';
import { Button, Col, DatePicker, Row, Space } from 'antd';
import { Column } from '@ant-design/charts';
import { PerDayAvgAmountResponse, PerDayCountResponse } from '../../../redux/api/reportsApi';
import {avgAmountTooltipSettings, countTooltipSettings, initialPeriodOneMonth, IPeriod, yAxis} from '../Dashboard';

interface ColumnGraphProps {
    height: number;
    onChangePeriod: (period: IPeriod) => void;
    data?: PerDayCountResponse[] | PerDayAvgAmountResponse[];
    isCount: boolean;
}
const ColumnGraph = ({ height, onChangePeriod, data, isCount }: ColumnGraphProps) => {
    const [period, setPeriod] = useState<IPeriod>(initialPeriodOneMonth);

    return (
        <Row gutter={[24, 24]}>
            <Col span={24}>
                <Space>
                    <DatePicker
                        value={period.start}
                        onChange={(date) => setPeriod((prev) => ({ ...prev, start: date ?? undefined }))}
                        placeholder="Початкова дата"
                        disabledDate={(current) =>
                            period.end
                                ? current.startOf('day').isBefore(period.end.subtract(3, 'month').startOf('day')) ||
                                  current.startOf('day').isAfter(period.end.startOf('day'))
                                : false
                        }
                    />
                    <DatePicker
                        value={period.end}
                        onChange={(date) => setPeriod((prev) => ({ ...prev, end: date ?? undefined }))}
                        placeholder="Кінцева дата"
                        disabledDate={(current) =>
                            period.start
                                ? current.startOf('day').isAfter(period.start.add(3, 'month').startOf('day')) ||
                                  current.startOf('day').isBefore(period.start.startOf('day'))
                                : false
                        }
                    />
                    <Button disabled={!period.start || !period.end} onClick={() => onChangePeriod(period)}>
                        Показати
                    </Button>
                </Space>
            </Col>
            <Col span={24}>
                {isCount ? <Column
                    data={data || []}
                    height={height}
                    xField="day"
                    yField="count"
                    tooltip={countTooltipSettings}
                    yAxis={yAxis}
                /> : <Column
                    data={data || []}
                    height={height}
                    xField="day"
                    yField="amount"
                    tooltip={avgAmountTooltipSettings}
                    yAxis={yAxis}
                />}
            </Col>
        </Row>
    );
};

export default ColumnGraph;
