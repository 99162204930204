import { configureStore } from '@reduxjs/toolkit';
// todo test, remove after create next reducer
import { authApi } from './api/authApi';
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import rootReducer from './reducers';
import { rolesApi } from './api/rolesApi';
import { userApi } from './api/userApi';
import { alertsApi } from './api/alertsApi';
import { dictionaryApi } from './api/dictionaryApi';
import { rulesApi } from './api/rulesApi';
import { transactionsApi } from './api/transactionsApi';
import { reportsApi } from './api/reportsApi';
export const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                ignoredPaths: ['userApi'],
            },
        }).concat([
            authApi.middleware,
            rolesApi.middleware,
            userApi.middleware,
            alertsApi.middleware,
            dictionaryApi.middleware,
            rulesApi.middleware,
            transactionsApi.middleware,
            reportsApi.middleware,
        ]),
});
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
