import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { Row, Table, TablePaginationConfig, TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment/moment';
import { QueryOptions } from 'odata-query';

import { LeftOutlined } from '@ant-design/icons';

import { DATE_FORMAT, TIME_WITH_SECONDS_FORMAT } from '../../../constants/Alert';
import { defaultHistoryQuery, rulesFields, statusCatalog, statusFilter } from '../../../constants/History';
import {
    HISTORY_PARAMETR_EXPIREINTERVAL,
    HISTORY_PARAMETR_INTERVAL,
    HISTORY_PARAMETR_ISACTIVE,
    HISTORY_PARAMETR_PARAMS,
    HISTORY_PARAMETR_RISKLEVEL,
    HISTORY_PARAMETR_URGENCYTYPE,
} from '../../../constants/Rules';
import { HISTORY_NO_DATA } from '../../../constants/common';
import { PERIODS_TRANSLATE } from '../../../constants/enums';
import { getColumnSearchProps } from '../../../helpers/getColumnSearchProps';
import { getHistoryFilter } from '../../../helpers/getHistoryFilters';
import getTopSkip from '../../../helpers/getTopSkip';
import { isArray, isArrayOfString, isObject, isSimpleType } from '../../../helpers/valueTypeCheck';
import { useEscape } from '../../../hooks';
import { RuleHistory, RuleModel } from '../../../models/RulesModel';
import { useGetRuleHistoryQuery } from '../../../redux/api/rulesApi';
import HistoryRow from '../../common/HistoryRow';

interface RulesHistoryProps {
    selectedRule: RuleModel;
    onClose: () => void;
}

const historyValue = (value: Partial<RuleModel>) =>
    value ? (
        Object.keys(value).map((key, index) => {
            switch (key) {
                case HISTORY_PARAMETR_ISACTIVE:
                    return <HistoryRow value={value.isActive ? 'Так' : 'Ні'} key={index} />;
                case HISTORY_PARAMETR_PARAMS:
                    let rowValue = (value as any).params.value;
                    switch (true) {
                        case isObject(rowValue):
                            rowValue =
                                'Від: ' +
                                (rowValue.timeFrom ?? rowValue.beforeAge) +
                                '; До: ' +
                                (rowValue.timeTo ?? rowValue.afterAge) +
                                ';';
                            break;
                        case isArrayOfString(rowValue):
                            rowValue = rowValue.join(', ');
                            break;
                        case isArray(rowValue):
                            rowValue = rowValue
                                .map((item: any) => item.account + ': ' + item.ob22.join(','))
                                .join('; ');
                            break;
                        case rowValue in PERIODS_TRANSLATE:
                            rowValue = PERIODS_TRANSLATE[rowValue as keyof typeof PERIODS_TRANSLATE];
                            break;
                        case isSimpleType(rowValue):
                            rowValue = rowValue.toString();
                            break;
                        default:
                            rowValue = HISTORY_NO_DATA;
                            break;
                    }
                    return <HistoryRow value={rowValue} key={index} />;
                case HISTORY_PARAMETR_INTERVAL:
                case HISTORY_PARAMETR_EXPIREINTERVAL:
                case HISTORY_PARAMETR_RISKLEVEL:
                case HISTORY_PARAMETR_URGENCYTYPE:
                    return (
                        <HistoryRow
                            value={value[key]?.description || value[key]?.code || HISTORY_NO_DATA}
                            key={index}
                        />
                    );
                default:
                    return (
                        <HistoryRow
                            value={typeof (value as any)[key] !== 'object' ? (value as any)[key] : HISTORY_NO_DATA}
                            key={index}
                        />
                    );
            }
        })
    ) : (
        <div>Дані відсутні</div>
    );

const History: FC<RulesHistoryProps> = ({ selectedRule, onClose }) => {
    useEscape(onClose);
    const dateInputRef = useRef(null);
    const authorInputRef = useRef(null);
    const defaultFilter = { entryId: selectedRule.id };

    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
    });

    const [filter, setFilter] = useState<QueryOptions<RuleHistory>['filter']>(defaultFilter);

    const queryData = useMemo<Partial<QueryOptions<RuleHistory>>>(
        () => ({
            ...defaultHistoryQuery,
            ...getTopSkip(pagination.pageSize, pagination.current),
            filter: filter,
        }),
        [pagination, filter],
    );

    const { data: ruleHistory, isLoading, isFetching } = useGetRuleHistoryQuery(queryData);

    const count = ruleHistory?.count;

    useEffect(() => {
        setPagination((currentPagination) => ({ ...currentPagination, total: count }));
    }, [count]);

    const handleTableChange: TableProps<RuleHistory>['onChange'] = (newPagination, filters, _sorter) => {
        setFilter([defaultFilter, ...getHistoryFilter(filters)]);
        setPagination(newPagination);
    };

    const column: ColumnsType<RuleHistory> = [
        {
            title: 'Тип',
            dataIndex: 'action',
            key: 'action',
            render: (value) => statusCatalog[value],
            filters: statusFilter,
            width: 120,
            ellipsis: true,
        },
        {
            title: 'Дата',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (value) => moment(value).format(DATE_FORMAT),
            ...getColumnSearchProps<RuleHistory>(dateInputRef, 'date'),
            width: 100,
            ellipsis: true,
        },
        {
            title: 'Час',
            dataIndex: 'createdDate',
            key: 'time',
            render: (value) => moment(value).format(TIME_WITH_SECONDS_FORMAT),
            width: 100,
            ellipsis: true,
        },
        {
            title: 'Автор',
            dataIndex: 'userId',
            key: 'user',
            render: (value) => `${value.lastName} ${value.firstName}`,
            ...getColumnSearchProps<RuleHistory>(authorInputRef, 'fio'),
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Параметр',
            dataIndex: 'newValue',
            key: 'param',
            render: (value) =>
                Object.keys(value).map((key, index) => (
                    <HistoryRow
                        value={key === HISTORY_PARAMETR_PARAMS ? value.params.description : rulesFields[key] || key}
                        key={index}
                    />
                )),
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Значення "До"',
            dataIndex: 'prevValue',
            key: 'prevValue',
            render: (value) => historyValue(value),
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Значення "Після"',
            dataIndex: 'newValue',
            key: 'newValue',
            render: (value) => historyValue(value),
            width: 150,
            ellipsis: true,
        },
    ];

    return (
        <>
            <Row>
                <div onClick={onClose} className={'user-history__title'}>
                    <LeftOutlined />
                    Історія "{selectedRule?.shortName}"
                </div>
            </Row>
            <Table
                size="small"
                rowKey="id"
                bordered
                loading={isLoading || isFetching}
                columns={column}
                dataSource={ruleHistory ? ruleHistory.items : []}
                pagination={pagination}
                onChange={handleTableChange}
            />
        </>
    );
};

export default History;
