import { Button, DatePicker, Form, Row } from 'antd';
import React, { FC, useCallback } from 'react';
import { DATE_FORMAT, MONEY_MASK } from '../../../constants';
import { useForm } from 'antd/lib/form/Form';
import { Dayjs } from 'dayjs';
import { MaskedInput } from 'antd-mask-input';

const { RangePicker } = DatePicker;

interface FormValues {
    amount: string;
    period: [Dayjs, Dayjs];
}

interface PaymentParamsFormProps {
    onSubmit: (amount: number, period: [Dayjs, Dayjs]) => void;
    onClose: () => void;
}

const PaymentParamsForm: FC<PaymentParamsFormProps> = ({ onSubmit, onClose }) => {
    const [form] = useForm();
    const submit = useCallback(
        (values: FormValues) => {
            const amount = (values.amount ? Number(values.amount.replace(',', '.')) : 320000) * 100;
            onSubmit(amount, values.period);
            onClose();
        },
        [onSubmit, onClose],
    );

    return (
        <Form<FormValues>
            form={form}
            onFinish={submit}
            layout="vertical"
            validateMessages={{
                required: 'Обов’язкове поле!',
            }}>
            <Form.Item label="Період" name="period" rules={[{ required: true }]}>
                <RangePicker className="wWide" format={DATE_FORMAT} />
            </Form.Item>
            <Form.Item label="Сума" name="amount">
                <MaskedInput placeholder="Введіть суму" mask={[MONEY_MASK]} className="wWide" />
            </Form.Item>
            <Row justify="end">
                <Button type="primary" htmlType="submit">
                    Відправити
                </Button>
            </Row>
        </Form>
    );
};

export default PaymentParamsForm;
