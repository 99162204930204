import { EmployeeModel } from '../models/UserModel';

export const getUserName = (user: EmployeeModel) => {
    const result = [];
    if (user.lastName) {
        result.push(user.lastName);
    }
    if (user.firstName) {
        result.push(user.firstName);
    }
    if (user.middleName) {
        result.push(user.middleName);
    }
    return result.join(' ');
};
