import { Button, Card, Form, Input, notification, Spin } from 'antd';
import React, { FC, useEffect } from 'react';
import { useChangeUserPasswordMutation } from '../redux/api/authApi';
import { getChangePasswordData, logout } from '../redux/features/authSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { Navigate } from 'react-router-dom';
interface ChangePasswordValues {
    login: string;
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

const ChangePassword: FC = () => {
    const [changeUserPassword, { isLoading, error, isSuccess }] = useChangeUserPasswordMutation();

    const onFinish = (values: ChangePasswordValues) => {
        changeUserPassword({
            login: values.login,
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
        });
    };

    const dispatch = useAppDispatch();
    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Пароль успішно оновлено',
                description: 'Авторизуйтесь, використовуючи новий пароль',
            });
            dispatch(logout());
        }
    }, [isSuccess, dispatch]);

    useEffect(() => {
        if (error && 'data' in error) {
            notification.error({
                message: (error.data as any).message,
            });
        }
    }, [error]);

    const changePasswordData = useAppSelector(getChangePasswordData);
    if (!changePasswordData) {
        return <Navigate to={'/'} />;
    }

    return (
        <div className="login__form">
            <Spin spinning={isLoading}>
                <Card title={<img src={'logo_big.svg'} width={238} alt="logo" />}>
                    <Form<ChangePasswordValues>
                        initialValues={{
                            login: changePasswordData?.login,
                        }}
                        name="basic"
                        layout="vertical"
                        onFinish={onFinish}
                        autoComplete="off">
                        <Form.Item
                            label="Ім’я користувача"
                            name="login"
                            rules={[{ required: true, message: `Обов'язкове поле!` }]}>
                            <Input disabled />
                        </Form.Item>
                        <Form.Item
                            name="oldPassword"
                            label="Старий тимчасовий пароль"
                            rules={[{ required: true }]}
                            hasFeedback>
                            <Input.Password />
                        </Form.Item>
                        <Form.Item name="newPassword" label="Новий пароль" rules={[{ required: true }]} hasFeedback>
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            name="confirmPassword"
                            label="Підтвердіть пароль"
                            dependencies={['newPassword']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Паролі не співпадають!'));
                                    },
                                }),
                            ]}>
                            <Input.Password />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Підтвердити
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Spin>
        </div>
    );
};

export default ChangePassword;
