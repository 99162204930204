import React, { useEffect } from 'react';
import { Button, Upload, UploadProps, notification, Space } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import FileService from '../../../services/file-service';
import { DictionaryModel } from '../../../models/DictionaryModel';
import { useImportCsvDictionaryMutation } from '../../../redux/api/dictionaryApi';
import { BASE_NOTIFICATION_CONFIG } from '../../../constants';
import {ActivityTypes} from "../../../constants/enums/ActivitiTypes";

interface UploadFileProps {
    dictionary?: DictionaryModel;
    showControls: boolean;
    relevant: ActivityTypes;
}
const UploadFile = ({ dictionary, showControls, relevant }: UploadFileProps) => {
    const [uploadFile, { isLoading, error }] = useImportCsvDictionaryMutation();
    const downloadTemplate = () => {
        if (dictionary) FileService.downloadBlackList(dictionary.name, relevant);
    };

    const customRequest: UploadProps['customRequest'] = ({ file, onSuccess, onError }) => {
        const formData = new FormData();
        formData.append('file', file);
        uploadFile({ name: dictionary?.name as string, body: formData })
            .unwrap()
            .then(onSuccess)
            .catch(onError);
    };

    useEffect(() => {
        if (error) {
            notification.error({
                ...BASE_NOTIFICATION_CONFIG,
                message: (error as any)?.data?.message,
            });
        }
    }, [error]);

    return dictionary?.name.includes('black') ? (
        <Space>
            <Button type="primary" onClick={downloadTemplate} disabled={!dictionary || !showControls}>
                Вивантажити
            </Button>
            <Upload accept=".xls,.xlsx" customRequest={customRequest} showUploadList={false} disabled={isLoading}>
                <Button icon={<FileExcelOutlined />} disabled={!dictionary || !showControls} loading={isLoading}>
                    Додати файл
                </Button>
            </Upload>
        </Space>
    ) : null;
};

export default UploadFile;
