import React, { Component, FC, Key, LegacyRef, Ref, RefObject } from 'react';
import { Button, Col, DatePicker, Input, InputNumber, InputRef, Row } from 'antd';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import dayjs, { Dayjs } from 'dayjs';

import { DATE_FORMAT, DATE_FORMAT_FOR_QUERY } from '../../constants/Alert';
import { filterTypes } from '../../models/Catalog';
import { RangePickerProps } from 'antd/lib/date-picker';

interface FilterDropDownProps extends FilterDropdownProps {
    container: RefObject<InputRef> | LegacyRef<Component<PickerProps<Dayjs>>>;
    type: filterTypes;
    transformQuery?: (value: any) => string;
    onClose?: () => void;
}

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const FilterDropDown: FC<FilterDropDownProps> = ({
    type,
    container,
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    transformQuery,
    onClose,
}) => (
    <div className="filter" onKeyDown={(e) => e.stopPropagation()}>
        <div className="filter__wrapper">
            {type === 'date' ? (
                <DatePicker
                    className="filter__field"
                    placeholder="Оберіть дату"
                    ref={container as LegacyRef<Component<PickerProps<Dayjs>>>}
                    format={DATE_FORMAT}
                    onChange={(e) => setSelectedKeys(e ? [e?.format(DATE_FORMAT_FOR_QUERY)] : [])}
                    value={selectedKeys[0] ? dayjs(selectedKeys[0] as string, DATE_FORMAT_FOR_QUERY) : null}
                />
            ) : type === 'range' ? (
                <RangePicker
                    className="filter__field"
                    ref={container as LegacyRef<Component<RangePickerProps>>}
                    format={DATE_FORMAT}
                    onChange={(e) =>
                        setSelectedKeys(
                            e
                                ? [
                                      e?.[0]?.format(DATE_FORMAT_FOR_QUERY) as Key,
                                      e?.[1]?.format(DATE_FORMAT_FOR_QUERY) as Key,
                                  ]
                                : [],
                        )
                    }
                    value={
                        selectedKeys[0]
                            ? [
                                  dayjs(selectedKeys[0] as string, DATE_FORMAT_FOR_QUERY),
                                  dayjs(selectedKeys[1] as string, DATE_FORMAT_FOR_QUERY),
                              ]
                            : null
                    }
                />
            ) : type === 'amount' ? (
                <Row gutter={16}>
                    <Col>
                        <InputNumber
                            className="filter__field_amount"
                            ref={container as Ref<HTMLInputElement>}
                            placeholder="Min amount"
                            value={selectedKeys[0] as number}
                            onChange={(value) => setSelectedKeys(value ? [value, selectedKeys[1]] : [])}
                            onPressEnter={() => confirm()}
                            min={0}
                        />
                    </Col>
                    <Col>
                        <InputNumber
                            className="filter__field_amount"
                            placeholder="Max amount"
                            value={selectedKeys[1] as number}
                            onChange={(value) => setSelectedKeys(value ? [selectedKeys[0], value] : [])}
                            onPressEnter={() => confirm()}
                            min={0}
                        />
                    </Col>
                </Row>
            ) : type === 'textArea' ? (
                <TextArea
                    className="filter__field"
                    ref={container as RefObject<InputRef>}
                    placeholder="Введіть значення"
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(
                            e.target.value ? [transformQuery ? transformQuery(e.target.value) : e.target.value] : [],
                        )
                    }
                    onPressEnter={() => confirm()}
                />
            ) : (
                <Input
                    className="filter__field"
                    ref={container as RefObject<InputRef>}
                    placeholder="Введіть значення"
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(
                            e.target.value ? [transformQuery ? transformQuery(e.target.value) : e.target.value] : [],
                        )
                    }
                    onPressEnter={() => confirm()}
                />
            )}
        </div>
        <div className="filter__buttons">
            <Button
                onClick={() => {
                    clearFilters && clearFilters();
                    confirm();
                    onClose && onClose();
                }}
                type="link"
                size="small">
                Скинути
            </Button>
            <Button
                type="primary"
                onClick={() => {
                    confirm();
                    onClose && onClose();
                }}
                size="small">
                Ок
            </Button>
        </div>
    </div>
);

export default FilterDropDown;
