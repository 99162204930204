import { FC, ChangeEvent } from 'react';
import { ColumnModel } from '../../../../models/DictionaryModel';
import { MaskedInput } from 'antd-mask-input';

interface IProps {
    column: ColumnModel;
    value: ChangeEvent<HTMLSelectElement>;
    fraction?: number;
    onChange: any;
}

const MaskedInputNumber: FC<IProps> = ({ column, value, fraction, onChange }) => {
    return (
        <MaskedInput
            className="wWide"
            placeholder={column.description}
            min={0}
            maxLength={column.length ? column.length : undefined}
            value={`${value}`}
            onChange={onChange}
            mask={[
                {
                    mask: Number, // enable number mask
                    scale: fraction ?? 0, // digits after point, 0 for integers
                    signed: false, // disallow negative
                    normalizeZeros: false,
                    radix: ',', // fractional delimiter
                    mapToRadix: [',', '.'], // symbols to process as radix
                },
            ]}
        />
    );
};

export default MaskedInputNumber;
