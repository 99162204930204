import React from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { DISABLED_WHITESPACE } from '../../../constants/common';

interface IProps {
    paramIndex: number;
}
const Account = ({ paramIndex }: IProps) => (
    <Form.List name={['params', paramIndex, 'value']}>
        {(fields, { add, remove }) => (
            <Row gutter={[8, 8]}>
                {fields.map(({ key }) => (
                    <Col span={24} key={key}>
                        <Row gutter={[8, 8]}>
                            <Col>
                                <Form.Item
                                    name={[key, 'account']}
                                    rules={[
                                        { required: true },
                                        { whitespace: true, message: DISABLED_WHITESPACE },
                                        {
                                            min: 4,
                                            max: 4,
                                            // eslint-disable-next-line no-template-curly-in-string
                                            message: 'довжина має бути від ${min} до ${max} символів',
                                        },
                                    ]}>
                                    <Input maxLength={4} style={{ width: '60px' }} />
                                </Form.Item>
                            </Col>
                            <Form.List name={[key, 'ob22']}>
                                {(fields, { add, remove }) => {
                                    return (
                                        <>
                                            {fields.map(({ key }) => (
                                                <Col key={key}>
                                                    <Form.Item
                                                        name={key}
                                                        rules={[
                                                            { required: true },
                                                            {
                                                                min: 2,
                                                                max: 2,
                                                                message:
                                                                    // eslint-disable-next-line no-template-curly-in-string
                                                                    'довжина має бути від ${min} до ${max} символів',
                                                            },
                                                        ]}>
                                                        <Input maxLength={2} style={{ width: '40px' }} />
                                                    </Form.Item>
                                                </Col>
                                            ))}
                                            {fields.length > 1 && (
                                                <Col>
                                                    <Button
                                                        type="primary"
                                                        danger
                                                        onClick={() => remove(fields.length - 1)}>
                                                        Видалити OB22
                                                    </Button>
                                                </Col>
                                            )}
                                            <Col>
                                                <Button type="primary" onClick={() => add()}>
                                                    Додати OB22
                                                </Button>
                                            </Col>
                                        </>
                                    );
                                }}
                            </Form.List>
                        </Row>
                    </Col>
                ))}
                {fields.length > 1 && (
                    <Col>
                        <Button type="primary" danger onClick={() => remove(fields.length - 1)}>
                            Видалити рахунок
                        </Button>
                    </Col>
                )}
                <Col>
                    <Button type="primary" onClick={() => add({ account: '', ob22: [''] })}>
                        Додати Рахунок
                    </Button>
                </Col>
            </Row>
        )}
    </Form.List>
);

export default Account;
