import { ThemeConfig } from 'antd/es/config-provider/context';

export const colorPrimary = '#445271';
export const colorTextSecondary = '#FF8C8C';
export const colorTextPrimary = '#F83A39';
export const colorBg = 'rgba(0, 0, 0, 0.06)';
export const colorWhite = '#fff';
export const colorBlack = '#000';

export const mainTheme: ThemeConfig = {
    token: {
        colorPrimary,
    },
    components: {
        Layout: {
            colorBgHeader: colorWhite,
            colorBgTrigger: colorPrimary,
        },
        Menu: {
            colorBgElevated: colorPrimary,
            itemBg: colorPrimary,
            itemBorderRadius: 0,
            itemColor: colorWhite,
            itemHoverColor: colorTextSecondary,
            itemHoverBg: colorBg,
            itemSelectedColor: colorTextSecondary,
            itemSelectedBg: colorBg,
        },
        Calendar: {
            colorPrimary: colorTextPrimary,
        }
    },
};
