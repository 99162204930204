import React, { FC, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button, Card, Form, Input, notification, Spin } from 'antd';

import { RouteCode } from '../constants/enums';
import { LoginRequestPayload, useLoginUserMutation } from '../redux/api/authApi';
import { getChangePasswordData, getToken, getUser } from '../redux/features/authSlice';
import { useAppSelector } from '../redux/hooks';

const Login: FC = () => {
    const token = useAppSelector(getToken);
    const user = useAppSelector(getUser);
    const changePasswordData = useAppSelector(getChangePasswordData);
    const navigate = useNavigate();
    const [loginUser, { isLoading, error }] = useLoginUserMutation();

    const onFinish = (values: LoginRequestPayload) => {
        loginUser(values);
    };

    useEffect(() => {
        if (token && user) {
            if (user.menuFunctions?.length) {
                if (user.menuFunctions.some((menuFunc) => menuFunc.path === RouteCode.transactions))
                    navigate(`/${RouteCode.transactions}`);
                else navigate(`/${RouteCode.users}`);
            } else {
                notification.error({
                    message: 'У вас немає доступу до системи, вашій ролі не надано доступу до жодних вкладок',
                    description: 'Якщо виникла помилка, зверніться до адміністратора системи.',
                });
            }
        }
    }, [token, navigate, user]);

    useEffect(() => {
        if (changePasswordData) {
            navigate(`/${RouteCode.changePassword}`);
        }
    }, [changePasswordData, navigate]);

    useEffect(() => {
        if (error && 'data' in error) {
            notification.error({
                message: (error as any).originalStatus === 502 ? (error.data as any) : (error.data as any).message,
            });
        }
    }, [error]);

    return (
        <div className="login__form">
            <Spin spinning={isLoading}>
                <Card title={<img src={'logo.svg'} width={100} alt="logo" />}>
                    <Form<LoginRequestPayload>
                        name="basic"
                        layout="vertical"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        autoComplete="off">
                        <Form.Item
                            label="Логін"
                            name="login"
                            rules={[{ required: true, message: `Обов'язкове поле!` }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Пароль"
                            name="password"
                            rules={[{ required: true, message: `Обов'язкове поле!` }]}>
                            <Input.Password />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Ввійти
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Spin>
        </div>
    );
};

export default Login;
