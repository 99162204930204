import React, { FC, useCallback, useEffect, useState } from 'react';

import { Spin, Form, Button, Row, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import UserDataFields from './UserDataFields';

import { BASE_NOTIFICATION_CONFIG } from '../../../constants/common';
import { RoleModel } from '../../../models/RoleModel';
import { UpdateUserModel, UserModel } from '../../../models/UserModel';
import { useActivateUserMutation, useDeactivateUserMutation, useUpdateUserMutation } from '../../../redux/api/userApi';

interface UpdateUserFormProps {
    user: UserModel;
    onSuccess: () => void;
    setIsEditedTrue: () => void;
}
interface UserFormValues extends Omit<UpdateUserModel, 'id'> {
    activeStatus: boolean;
}

const commonKeys = ['firstName', 'lastName', 'middleName', 'email'];
const UpdateUserForm: FC<UpdateUserFormProps> = ({ user, onSuccess, setIsEditedTrue }) => {
    const [form] = useForm<UserFormValues>();
    const [updateUser, { isLoading }] = useUpdateUserMutation();
    const [deactivateUser, { isLoading: isDeactivating }] = useDeactivateUserMutation();
    const [activateUser, { isLoading: isActivating }] = useActivateUserMutation();
    const [isDisabledTempPass, setIsDisabledTempPass] = useState(true);
    const toggleIsDisabledTempPass = useCallback(() => setIsDisabledTempPass((prevState) => !prevState), []);

    useEffect(
        () =>
            form.setFieldsValue({
                ...user,
                roles: (user.roles as RoleModel[]).map((item: RoleModel) => item.name),
                activeStatus: !user.lockedDate,
            }),
        [user, form],
    );

    const changeUserAccessibility = useCallback(() => {
        const action = user.lockedDate ? activateUser : deactivateUser;
        action(user.id)
            .unwrap()
            .then(() => {
                notification.success({
                    ...BASE_NOTIFICATION_CONFIG,
                    message: `Користувача успішно ${user.lockedDate ? 'активовано' : 'деактивовано'}`,
                });
                onSuccess();
            })
            .catch((error) =>
                notification.error({
                    ...BASE_NOTIFICATION_CONFIG,
                    message: error.data.message,
                }),
            );
    }, [user, activateUser, deactivateUser, onSuccess]);

    const onFinish = useCallback(
        (values: UserFormValues) => {
            const finalUser: UpdateUserModel = {
                id: user.id,
                firstName: values.firstName,
                lastName: values.lastName,
                middleName: values.middleName,
                roles: (values.roles as string[]).map((item: string) => ({ name: item })) || [],
                email: values.email,
            };

            if (!isDisabledTempPass) {
                finalUser.password = values.password;
                finalUser.isTemporaryPassword = 1;
            }

            if (
                commonKeys.some((key: string) => (finalUser as any)[key] !== (user as any)[key]) ||
                finalUser.roles?.length !== user.roles?.length ||
                !finalUser.roles.every((selectRole) =>
                    user.roles.some((userRole) => (userRole as RoleModel).name === (selectRole as RoleModel).name),
                ) ||
                finalUser.password
            ) {
                updateUser(finalUser)
                    .unwrap()
                    .then(() => {
                        onSuccess();
                        notification.success({
                            ...BASE_NOTIFICATION_CONFIG,
                            message: `Користувача успішно оновлено`,
                        });
                    })
                    .catch((error) =>
                        notification.error({
                            ...BASE_NOTIFICATION_CONFIG,
                            message: error.data.message,
                        }),
                    );
            }

            if (values.activeStatus !== !user.lockedDate) changeUserAccessibility();
        },
        [changeUserAccessibility, isDisabledTempPass, onSuccess, updateUser, user],
    );

    return (
        <Spin spinning={isLoading || isDeactivating || isActivating}>
            <Form<UserFormValues>
                form={form}
                onChange={setIsEditedTrue}
                onFinish={onFinish}
                layout="vertical"
                validateMessages={{ required: 'Обов’язкове поле!' }}>
                <UserDataFields disabledLogin disableTempPass={isDisabledTempPass} />
                <Row justify="space-between">
                    <Button onClick={toggleIsDisabledTempPass}>Скинути пароль</Button>
                    <Button type="primary" htmlType="submit">
                        Зберегти
                    </Button>
                </Row>
            </Form>
        </Spin>
    );
};

export default UpdateUserForm;
