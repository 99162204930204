import { ColumnModel, DictionaryModel, RecordModel } from '../models/DictionaryModel';

export const sortDictionaryColumn = (selectedDict?: DictionaryModel) => {
    if (selectedDict)
        return [...selectedDict?.columns].sort((a: ColumnModel, b: ColumnModel) =>
            a.ord && b.ord ? a.ord - b.ord : a.colName > b.colName ? 1 : -1,
        );
};

export const sortDictionaryRecordByCode = (records?: RecordModel[]) => {
    if (records)
        return [...records].sort((a, b) => (a.code?.localeCompare(b.code, undefined, { numeric: true }) > 0 ? 1 : -1));
};

export const sortArrayOfObjectsByProperty = <T>(array: T[], property: keyof T) => {
    return [...array].sort((a: T, b: T) => (a[property] > b[property] ? 1 : -1));
};

export const localeCompareSort = <T>(a: T, b: T, key: keyof T, options?: object) =>
    (a?.[key] as string)?.localeCompare(b?.[key] as string, undefined, options ?? { numeric: true });
