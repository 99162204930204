import { QueryOptions } from 'odata-query';

import { RuleModel } from '../models/RulesModel';

export const RULES_TYPE_WARNING = 'warning';
export const RULES_TYPE_CRITERION = 'online';

export const RULES_OPTIONS_TYPE_VIEW = 'view';
export const RULES_OPTIONS_TYPE_EDIT = 'edit';

export const ACTIVITY_ALL = '2';
export const ACTIVITY_ACTIVE = '1';
export const ACTIVITY_DISABLE = '0';

export const RULES_OPTION_PARAMETER_TYPE_NUMBER = 'number';
export const RULES_OPTION_PARAMETER_TYPE_STRING = 'string';
export const RULES_OPTION_PARAMETER_TYPE_SELECT = 'select';
export const RULES_OPTION_PARAMETER_TYPE_OBJECT = 'object';
export const RULES_OPTION_PARAMETER_TYPE_ARRAY = 'array';
export const RULES_OPTION_PARAMETER_TYPE_VOCABULARY = 'vocabulary';
export const RULES_OPTION_PARAMETER_TYPE_DICTIONARY = 'dictionary';

export const HISTORY_PARAMETR_ISACTIVE = 'isActive';
export const HISTORY_PARAMETR_INTERVAL = 'interval';
export const HISTORY_PARAMETR_EXPIREINTERVAL = 'expireInterval';
export const HISTORY_PARAMETR_RISKLEVEL = 'riskLevel';
export const HISTORY_PARAMETR_URGENCYTYPE = 'urgencyType';
export const HISTORY_PARAMETR_PARAMS = 'params';
export const HISTORY_PARAMETR_VOCABULARY = 'vocabulary';

export const simpleRulesQuery: Partial<QueryOptions<RuleModel>> = {
    // @ts-ignore cause of type/code it`s not a key of RuleModel, but key of nested ruleVocabulary
    select: ['id', 'code', 'type/code', 'shortName', 'isActive'],
    orderBy: 'code asc',
};
