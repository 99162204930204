import { RefObject } from 'react';

import { InputRef } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';

import { SearchOutlined } from '@ant-design/icons';

import FilterDropDown from '../components/common/FilterDropDown';
import { DATE_FORMAT_FOR_QUERY } from '../constants';
import { BaseAlert } from '../models/Alert';
import { Transaction } from '../models/Transaction';
import { filterTypes } from '../models/Catalog';
import { DictionaryHistory, RecordModel } from '../models/DictionaryModel';
import { RuleHistory } from '../models/RulesModel';
import { EmployeeModel, UserHistoryModel } from '../models/UserModel';

export function getColumnSearchProps<
    T extends BaseAlert | UserHistoryModel | RuleHistory | DictionaryHistory | RecordModel | Transaction,
>(
    container: RefObject<InputRef>,
    type: filterTypes,
    options?: { dataIndex?: keyof T; transformQuery?: (value: any) => string; close: () => void },
): ColumnType<T> {
    const dataIndex: keyof T = options?.dataIndex as keyof T;
    return {
        filterDropdown: (filterProps) => (
            <FilterDropDown
                onClose={options?.close}
                container={container}
                type={type}
                transformQuery={options?.transformQuery}
                {...filterProps}
            />
        ),
        filterIcon: () => <SearchOutlined />,
        ...(dataIndex && {
            onFilter: (value, record) => {
                if (type === 'date') {
                    return dayjs(record[dataIndex] as string)
                        .format(DATE_FORMAT_FOR_QUERY)
                        .includes((value as string).toLowerCase());
                }

                if (type === 'fio') {
                    const employee = record[dataIndex] as EmployeeModel;
                    return (employee.firstName + employee.lastName)
                        ?.toLowerCase()
                        .includes((value as string).toLowerCase());
                }

                return (record[dataIndex] as string)
                    ?.toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase());
            },
        }),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(
                    () =>
                        type === 'date' || type === 'range'
                            ? container?.current?.focus()
                            : container?.current?.select(),
                    100,
                );
            }
        },
    };
}
