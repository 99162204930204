import { Dispatch, FC, SetStateAction, useCallback, useEffect } from 'react';
import { Form, notification } from 'antd';
import { DictionaryModel, FormValuesType } from '../../../models/DictionaryModel';
import { useAddRecordToDictionaryMutation } from '../../../redux/api/dictionaryApi';
import RecordDataFields from './RecordDataFields';
import { useForm } from 'antd/lib/form/Form';
import { replaceQuotesWithDoubleQuotesInObj } from '../../../helpers/replaceQuotesWithDoubleQuotesInObj';
import { BASE_NOTIFICATION_CONFIG } from '../../../constants/common';

interface CreateRecordFormProps {
    onSuccess: () => void;
    dictionary?: DictionaryModel;
    setIsEdited: Dispatch<SetStateAction<boolean>>;
    createRecordModal: boolean;
}
const CreateRecordForm: FC<CreateRecordFormProps> = ({ onSuccess, dictionary, setIsEdited, createRecordModal }) => {
    const [addRecord, { isLoading }] = useAddRecordToDictionaryMutation();
    const [form] = useForm<FormValuesType>();

    useEffect(() => {
        if (createRecordModal) {
            form.setFieldsValue({ dict_name: dictionary?.name, dict_description: dictionary?.description });
            return () => form.resetFields();
        }
    }, [dictionary, form, createRecordModal]);

    const handleSubmit = useCallback(
        (values: FormValuesType) => {
            addRecord(replaceQuotesWithDoubleQuotesInObj(values))
                .unwrap()
                .then(() => {
                    notification.success({ ...BASE_NOTIFICATION_CONFIG, message: 'Запис успішно додано' });
                    onSuccess();
                    setIsEdited(false);
                    form.resetFields();
                })
                .catch((error) => {
                    return notification.error({
                        ...BASE_NOTIFICATION_CONFIG,
                        message: error.status === 500 ? 'Запис з таким кодом вже існує' : error.data.message,
                    });
                });
        },
        [addRecord, form, onSuccess, setIsEdited],
    );

    return (
        <>
            <Form<FormValuesType>
                onChange={() => setIsEdited(true)}
                form={form}
                onFinish={handleSubmit}
                validateMessages={{
                    required: 'Обов’язкове поле!',
                }}
                layout="vertical">
                <RecordDataFields isLoading={isLoading} dictionary={dictionary} />
            </Form>
        </>
    );
};

export default CreateRecordForm;
