import { Tabs } from 'antd';
import { Key, useCallback, useEffect, useMemo, useState } from 'react';
import { DictionaryModel } from '../../models/DictionaryModel';
import { useGetDictionariesQuery } from '../../redux/api/dictionaryApi';
import HeaderDictionary from './components/Header';
import TableDictionary from './components/TableDictionary';
import History from './components/History';
import {ActivityTypes} from "../../constants/enums/ActivitiTypes";

const Dictionary = () => {
    const { data: dictionaries } = useGetDictionariesQuery();
    const [activeTab, setActiveTab] = useState<string>();
    const [selectedRows, setSelectedRows] = useState<Key[]>([]);
    const clearSelectedRows = useCallback(() => setSelectedRows([]), [setSelectedRows]);
    const [showHistory, setShowHistory] = useState(false);
    const [relevant, setRelevant] = useState(ActivityTypes.all);

    useEffect(() => {
        if (!!dictionaries) setActiveTab(`${dictionaries[0].id}`);
    }, [dictionaries]);

    const selectedDict: DictionaryModel | undefined = useMemo(
        () => (dictionaries && activeTab ? dictionaries.find((dict) => dict.id === +activeTab) : dictionaries ?  dictionaries[0] : undefined),
        [dictionaries, activeTab],
    );

    const handleTabClick = (activeKey: string) => {
        setShowHistory(false);
        clearSelectedRows();
        setActiveTab(activeKey);
    };

    const items = useMemo(
        () =>
            dictionaries?.map((dict) => ({
                label: (
                    <div className="antd__tab" title={dict.description}>
                        {dict.description}
                    </div>
                ),
                key: `${dict.id}`,
                disabled: dict.isActive === 0,
                children: (
                    <>
                        {showHistory ? (
                            <History dictionary={dict} onClose={() => setShowHistory(false)} />
                        ) : (
                            <TableDictionary
                                dictionary={dict}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                                showControls={selectedDict?.name.includes('black') || false}
                                relevant={relevant}
                                onChangeRelevant={setRelevant}
                            />
                        )}
                    </>
                ),
            })) || [],
        [dictionaries, selectedRows, showHistory, relevant],
    );

    return (
        <>
            <HeaderDictionary
                dictionary={selectedDict}
                dictionaries={dictionaries}
                activeTab={activeTab}
                onChangeTab={handleTabClick}
                selectedRows={selectedRows}
                clearSelectedRows={clearSelectedRows}
                showControls={selectedDict?.isExtendable === 1}
                openHistory={() => setShowHistory(true)}
                relevant={relevant}
            />
            <Tabs tabPosition={'left'} activeKey={activeTab} onChange={handleTabClick} items={items.sort((a, b) => +a?.key - +b?.key)} />
        </>
    );
};

export default Dictionary;
