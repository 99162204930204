import React, { FC } from 'react';

import { Form, Input, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import TextArea from 'antd/lib/input/TextArea';

import Account from './Account';
import DataField from './DataField';
import ParameterArray from './ParametrArray';
import ParameterObject from './ParametrObject';

import {
    RULES_OPTION_PARAMETER_TYPE_ARRAY,
    RULES_OPTION_PARAMETER_TYPE_NUMBER,
    RULES_OPTION_PARAMETER_TYPE_OBJECT,
    RULES_OPTION_PARAMETER_TYPE_SELECT,
} from '../../../constants/Rules';
import { DISABLED_WHITESPACE } from '../../../constants/common';
import { PERIODS_TRANSLATE } from '../../../constants/enums';
import { numberToInputString } from '../../../helpers/numberToInputString';
import {
    ArrayProperty,
    ObjProperties,
    OptionsType,
    RuleModel,
    RuleParamModel,
    RuleParamValueObj,
} from '../../../models/RulesModel';
import { EmployeeModel } from '../../../models/UserModel';
import { getUserName } from '../../../helpers/getUserName';

const activityItems = [
    {
        value: 0,
        label: 'Ні',
    },
    {
        value: 1,
        label: 'Так',
    },
];

interface ParamsProps {
    selectedRule?: RuleModel;
    optionType: OptionsType;
    users?: EmployeeModel[];
    withAssignedUsers?: boolean;
}

const Params: FC<ParamsProps> = ({ selectedRule, optionType, users, withAssignedUsers }) => (
    <div>
        <div className="rules-block__sub_title">Параметри</div>
        <div className="rules-block__items">
            <Form.Item name="id" hidden>
                <Input />
            </Form.Item>
            <DataField
                describe="Прапор активності"
                value={selectedRule?.isActive ? 'Так' : 'Ні'}
                optionType={optionType}>
                <Form.Item name="isActive">
                    <Select options={activityItems} />
                </Form.Item>
            </DataField>
            <DataField describe="Назва" value={selectedRule?.description} optionType={optionType}>
                <Form.Item
                    name="description"
                    rules={[
                        { required: true, message: 'Обов`язково' },
                        { whitespace: true, message: DISABLED_WHITESPACE },
                    ]}>
                    <TextArea rows={4} />
                </Form.Item>
            </DataField>
            {withAssignedUsers && (
                <DataField
                    describe="Призначені користувачі"
                    value={selectedRule?.assignedUsers?.map((user) => getUserName(user))?.join('; ')}
                    optionType={optionType}>
                    <Form.Item name="assignedUsers" rules={[{ required: true, message: 'Обов`язково' }]}>
                        <Select
                            mode="multiple"
                            options={users?.map((user) => ({ value: user.id, label: getUserName(user) }))}
                        />
                    </Form.Item>
                </DataField>
            )}
            <DataField describe="Коротка назва" value={selectedRule?.shortName} optionType={optionType}>
                <Form.Item
                    name="shortName"
                    rules={[
                        { required: true, message: 'Обов`язково' },
                        { whitespace: true, message: DISABLED_WHITESPACE },
                    ]}>
                    <Input />
                </Form.Item>
            </DataField>
            {(selectedRule?.params as RuleParamModel[])?.map((param, index) => (
                <DataField
                    key={param.id}
                    describe={param.label}
                    value={
                        param.type.code === RULES_OPTION_PARAMETER_TYPE_OBJECT && optionType === 'view'
                            ? Array.isArray(param.value)
                                ? (param.value as object[])
                                      .map((item: any) => `${item.account}/${item.ob22.toString()}`)
                                      .join('; ')
                                      .toString()
                                : Object.keys(param.value)
                                      .map(
                                          (key) =>
                                              `${(param.properties as ObjProperties)?.[key]?.label}: ${(
                                                  param.value as RuleParamValueObj
                                              )?.[key]}`,
                                      )
                                      .join('; ')
                            : param.type.code === RULES_OPTION_PARAMETER_TYPE_ARRAY && optionType === 'view'
                              ? (param.value as string[]).join('; ')
                              : param.type.code === RULES_OPTION_PARAMETER_TYPE_SELECT && optionType === 'view'
                                ? PERIODS_TRANSLATE[param.value as keyof typeof PERIODS_TRANSLATE] || ''
                                : param.type.code === RULES_OPTION_PARAMETER_TYPE_NUMBER
                                  ? numberToInputString(param.value as number, param.fraction)
                                  : (param.value as string)
                    }
                    optionType={optionType}>
                    <>
                        <Form.Item name={['params', index, 'id']} hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name={['params', index, 'type']} hidden>
                            <Input />
                        </Form.Item>
                        {param.type?.code === RULES_OPTION_PARAMETER_TYPE_ARRAY ? (
                            <ParameterArray
                                name={['params', index, 'value']}
                                min={(param.properties as ArrayProperty)?.minLength}
                                max={(param.properties as ArrayProperty)?.maxLength}
                                label={param.label}
                            />
                        ) : param.type?.code === RULES_OPTION_PARAMETER_TYPE_OBJECT ? (
                            Array.isArray(param.value) ? (
                                <Account paramIndex={index} />
                            ) : (
                                <ParameterObject value={param.value as object} param={param} paramIndex={index} />
                            )
                        ) : (
                            <Form.Item
                                name={['params', index, 'value']}
                                rules={[{ whitespace: true, message: DISABLED_WHITESPACE }]}>
                                {param.type?.code === RULES_OPTION_PARAMETER_TYPE_SELECT ? (
                                    <Select options={param.options} />
                                ) : param.type?.code === RULES_OPTION_PARAMETER_TYPE_NUMBER ? (
                                    <MaskedInput
                                        mask={[
                                            {
                                                mask: Number, // enable number mask
                                                scale: param.fraction ?? 0, // digits after point, 0 for integers
                                                signed: false, // disallow negative
                                                normalizeZeros: false,
                                                radix: ',', // fractional delimiter
                                                mapToRadix: [',', '.'], // symbols to process as radix
                                            },
                                        ]}
                                        className="wWide"
                                    />
                                ) : (
                                    <Input className="wWide" />
                                )}
                            </Form.Item>
                        )}
                    </>
                </DataField>
            ))}
        </div>
    </div>
);

export default Params;
