import React, { FC, useEffect, useMemo } from 'react';

import { notification, Spin } from 'antd';

import Content from './components/Content';

import { RULES_TYPE_CRITERION, simpleRulesQuery } from '../../constants/Rules';
import { BASE_NOTIFICATION_CONFIG } from '../../constants/common';
import { filterRules } from '../../helpers/filterRules';
import { RuleModel } from '../../models/RulesModel';
import { useGetRulesQuery } from '../../redux/api/rulesApi';
import { useGetEmployeesQuery } from '../../redux/api/userApi';

interface RulesProps {
    type: 'warning' | 'online';
}

const Rules: FC<RulesProps> = ({ type }) => {
    const { data: rules, error, isLoading, isFetching } = useGetRulesQuery(simpleRulesQuery);
    const {
        data: users,
        error: errorUsers,
        isFetching: isFetchingUsers,
    } = useGetEmployeesQuery({}, { skip: type === RULES_TYPE_CRITERION });
    const filtered = useMemo(() => filterRules(type, rules as RuleModel[]), [rules, type]);

    useEffect(() => {
        if (error) {
            notification.error({
                ...BASE_NOTIFICATION_CONFIG,
                message: (error as any)?.data?.message,
            });
        }
    }, [error]);

    useEffect(() => {
        if (errorUsers) {
            notification.error({
                ...BASE_NOTIFICATION_CONFIG,
                message: (errorUsers as any)?.data?.message,
            });
        }
    }, [errorUsers]);

    return (
        <Spin spinning={isLoading || isFetching || isFetchingUsers}>
            {filtered && <Content type={type} users={users || []} collection={filtered} />}
        </Spin>
    );
};
export default Rules;
