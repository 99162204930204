import { createApi } from '@reduxjs/toolkit/query/react';
import customBaseQuery from './common/customBaseQuery';
import { BaseAlert } from '../../models/Alert';
import buildQuery, { QueryOptions } from 'odata-query';

type AlertResponse = {
    count: number;
    items: BaseAlert[];
};

export const alertsApi = createApi({
    reducerPath: 'alertsApi',
    baseQuery: customBaseQuery,
    tagTypes: ['Alerts'],
    endpoints: (builder) => ({
        getAlerts: builder.query<AlertResponse, Partial<QueryOptions<BaseAlert>>>({
            query(queryData) {
                const queryString = buildQuery(queryData);
                return {
                    url: `process/alerts${queryString}`,
                    method: 'GET',
                };
            },
            providesTags: ['Alerts'],
        }),
        updateStatus: builder.mutation<any, any>({
            query(data) {
                const { skipUpdateCache, ...rest } = data;
                return {
                    url: `process/alerts/status`,
                    method: 'PATCH',
                    body: { ...rest },
                };
            },
            invalidatesTags: (result, error, arg) => {
                return arg.skipUpdateCache ? [] : ['Alerts'];
            },
        }),
        updateDeadline: builder.mutation<any, any>({
            query(data) {
                const ids = data.ids ?? [];
                return {
                    url: `process/alerts/deadline`,
                    method: 'PATCH',
                    body: { date: data.date, alerts: ids },
                };
            },
            invalidatesTags: ['Alerts'],
        }),
    }),
});

export const {
    useGetAlertsQuery,
    useUpdateStatusMutation,
    useUpdateDeadlineMutation,
} = alertsApi;
