import { useEffect, useMemo, useRef, useState } from 'react';
import { Table, TablePaginationConfig, TableProps, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { QueryOptions } from 'odata-query';
import ResizableColumn from '../../components/common/ResizebleColumn';
import {SESSION_STORAGE_KEY, ALERT_PARAMETER, DATE_FORMAT_WITH_TIME} from '../../constants';
import { getAlertsFilters } from '../../helpers/getAlertsQuery';
import { getColumnSearchProps } from '../../helpers/getColumnSearchProps';
import getTopSkip from '../../helpers/getTopSkip';
import { BaseAlert } from '../../models/Alert';
import { useGetAlertsQuery } from '../../redux/api/alertsApi';
import { EmployeeModel } from '../../models/UserModel';
import { useGetEmployeesQuery } from '../../redux/api/userApi';
import { getUserName } from '../../helpers/getUserName';

const defaultOrder = 'id desc';
const shift = 32 + 16 + 24 + 16;

const Alerts = () => {
    const inputRef = useRef(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const sizes = useMemo(() => {
        const tableSizeJson = window.localStorage.getItem('alertTableSize');
        return tableSizeJson ? JSON.parse(tableSizeJson) : null;
    }, []);
    const [filters, setFilters] = useState<any>({});
    const { data: users } = useGetEmployeesQuery({});

    const columns: ColumnsType<BaseAlert> = [
        {
            title: (
                <ResizableColumn colIndex={0} storageKey={SESSION_STORAGE_KEY}>
                    Дата
                </ResizableColumn>
            ),
            dataIndex: ALERT_PARAMETER.createdDate,
            render: (value) => value && moment(value).format(DATE_FORMAT_WITH_TIME),
            ...getColumnSearchProps<BaseAlert>(inputRef, 'date'),
            key: ALERT_PARAMETER.createdDate,
            filteredValue: filters[ALERT_PARAMETER.createdDate] || null,
            width: sizes?.[0] || 250,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={1} storageKey={SESSION_STORAGE_KEY}>
                    Alert ID
                </ResizableColumn>
            ),
            dataIndex: ALERT_PARAMETER.id,
            key: ALERT_PARAMETER.id,
            ...getColumnSearchProps<BaseAlert>(inputRef, 'string'),
            filteredValue: filters[ALERT_PARAMETER.id] || null,
            width: sizes?.[1] || 250,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={2} storageKey={SESSION_STORAGE_KEY}>
                    Назва
                </ResizableColumn>
            ),
            dataIndex: ALERT_PARAMETER.ruleDescription,
            ...getColumnSearchProps<BaseAlert>(inputRef, 'string'),
            filteredValue: filters[ALERT_PARAMETER.ruleDescription.join('/')] || null,
            key: ALERT_PARAMETER.ruleDescription.join('/'),
            width: sizes?.[2] || 250,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={3} storageKey={SESSION_STORAGE_KEY}>
                    Отримувачі
                </ResizableColumn>
            ),
            dataIndex: ALERT_PARAMETER.assignedUsers,
            key: ALERT_PARAMETER.assignedUserId.join('/'),
            render: (value: EmployeeModel[]) => value?.map((user) => getUserName(user)).join(', '),
            filters: users?.map((user: EmployeeModel) => ({ text: getUserName(user), value: user.id })),
            filteredValue: filters[ALERT_PARAMETER.assignedUserId.join('/')] || null,
            width: sizes?.[3] || 250,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={4} storageKey={SESSION_STORAGE_KEY}>
                    Отримувачі Email
                </ResizableColumn>
            ),
            dataIndex: ALERT_PARAMETER.assignedUsers,
            key: ALERT_PARAMETER.assignedUserEmail.join('/'),
            render: (value: EmployeeModel[]) => value?.map((user) => user.email).join(', '),
            filters: users?.map((user: EmployeeModel) => ({ text: user.email, value: user.email })),
            filteredValue: filters[ALERT_PARAMETER.assignedUserEmail.join('/')] || null,
            width: sizes?.[4] || 250,
            ellipsis: true,
            sorter: true,
        },
    ];

    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 20,
        showSizeChanger: true,
    });
    const [order, setOrder] = useState<QueryOptions<BaseAlert>['orderBy']>(defaultOrder);
    const queryData = useMemo<Partial<QueryOptions<BaseAlert>>>(
        () => ({
            orderBy: order,
            count: true,
            ...getTopSkip(pagination.pageSize, pagination.current),
            filter: getAlertsFilters(filters),
        }),
        [filters, order, pagination],
    );
    const { data: alerts, isFetching } = useGetAlertsQuery(queryData);
    const handleTableChange: TableProps<BaseAlert>['onChange'] = (newPagination, filters, sorter: any) => {
        setFilters(filters);
        if (sorter?.order) {
            setOrder(`${sorter?.columnKey} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`);
        }
        setPagination(newPagination);
    };

    useEffect(() => {
        setPagination((currentPagination) => ({ ...currentPagination, total: alerts?.count }));
    }, [alerts?.count]);

    const tableHeight = (wrapperRef.current as HTMLDivElement)?.offsetHeight;

    return (
        <div className="alert__list">
            <div>
                <Button onClick={() => setFilters({})}>Скинути фільтр</Button>
            </div>
            <div className="alert_table" ref={wrapperRef}>
                <Table<BaseAlert>
                    size="small"
                    scroll={{
                        x: 600,
                        y: tableHeight ? tableHeight - shift : undefined,
                    }}
                    loading={isFetching}
                    rowKey="id"
                    bordered
                    columns={columns}
                    dataSource={alerts?.items || []}
                    pagination={pagination}
                    onChange={handleTableChange}
                />
            </div>
        </div>
    );
};

export default Alerts;
