import { BoolFromInt } from './enums/BoolFromInt';

export const TRANSACTIONS_PARAMETERS = Object.freeze({
    ID: 'id',
    CLIENT_ID: 'clientId',
    MERCHANT_ID: 'merchantId',
    CARD_NUMBER: 'cardNumber',
    TRANSACTION_ID: 'transactionId',
    TRANSACTION_DATE: 'transactionDate',
    TRANSACTION_TERMINAL_ID: 'transactionTerminalId',
    AMOUNT: 'amount',
    CARD_HOLDER: 'cardHolder',
    IP: 'ip',
    PLATFORM: 'platform',
    PAYMENT_TYPE: 'paymentType',
    MERCHANT_SUM: 'merchantSum',
    BROWSER_DATA: 'browserData',
    CALLBACK_URL: 'callbackURL',
    FAILED_CALLBACK: 'failedCallback',
    SUCCESS_CALLBACK: 'successCallback',
    RECIPIENT_WALLET_ID: 'recipientWalletId',
    RECIPIENT_WALLET_USER_NAME: 'recipientWalletUserName',
    SENDER_CARD_CRYPTOGRAM: 'senderCardCryptogram',
    SENDER_CARD_ECI_INDICATOR: 'senderCardEciIndicator',
    SENDER_CARD_EXP_MON: 'senderCardExpMon',
    SENDER_CARD_EXP_YEAR: 'senderCardExpYear',
    SENDER_CARD_GATEWAY: 'senderCardGateway',
    SENDER_CARD_PAN: 'senderCardPAN',
    TRANSFER_SUM: 'transferSum',
    STATUS: 'status',
    RULES: 'rules',
    RULES_CODES: 'rulesCodes',
    FINGER_PRINT: 'fingerprint',
    REJECT_CODE: 'rejectCode',
    REJECT_MESSAGE: 'rejectMessage',
    ORDER_ID: 'orderId',
    WITHDRAW_TYPE: 'withdrawType',
    EXTERNAL_TRANSACTION_ID: 'externalTransactionId',
    CREATED_DATE: 'createdDate',
    CREATED_USER: 'createdUser',
    UPDATED_DATE: 'updatedDate',
    UPDATED_USER: 'updatedUser',
    VALIDATED: 'validated',
    MERCHANT_NAME: ['merchant', 'name'],
    MERCHANT_WEBSITE: ['merchant', 'website'],
    MERCHANT_AGREGATOR: ['merchant', 'agregator'],
});
export const TRANSACTION_SESSION_STORAGE_KEY = 'transactionsTableSize';
export const STATUS_LOCALE = {
    [BoolFromInt.TRUE]: 'Успішно',
    [BoolFromInt.FALSE]: 'Відхилено',
};
export const STATUS_OPTIONS = [
    { text: STATUS_LOCALE[BoolFromInt.TRUE], value: BoolFromInt.TRUE },
    { text: STATUS_LOCALE[BoolFromInt.FALSE], value: BoolFromInt.FALSE },
];

export const VALIDATED_LOCALE = {
    [BoolFromInt.TRUE]: 'Обробка',
    [BoolFromInt.FALSE]: 'Збереження',
};
export const VALIDATED_OPTIONS = [
    { text: VALIDATED_LOCALE[BoolFromInt.TRUE], value: BoolFromInt.TRUE },
    { text: VALIDATED_LOCALE[BoolFromInt.FALSE], value: BoolFromInt.FALSE },
];

export const BOOL_FROM_INT_OPTIONS = [BoolFromInt.TRUE, BoolFromInt.FALSE];
