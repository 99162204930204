import { RouteList } from '../constants/enums';
import { MenuFunctionModel } from '../models/MenuFunctionModel';
import { BaseRouteModel, ExtendedRouteModel } from '../models/RouterModel';

function checkRouteList(
    item: MenuFunctionModel,
    routeList: readonly BaseRouteModel[],
): BaseRouteModel | MenuFunctionModel | undefined {
    for (let i = 0; i < routeList.length; i++) {
        if (routeList[i].code === item.path) return routeList[i];
    }
}

export function getRouting(functions: MenuFunctionModel[] = []): ExtendedRouteModel[] {
    const result: any[] = [];

    functions.forEach((item: MenuFunctionModel) => {
        const route = checkRouteList(item, RouteList);
        if (route || item.children?.length) {
            const pushItem = {
                ...route,
                name: item.name,
                ord: item.ord ?? 0,
                children: getRouting(item.children),
                icon: route?.icon || item.icon,
            };
            result.push(pushItem);
        }
    });
    return result;
}
