import buildQuery, { QueryOptions } from 'odata-query';

import { createApi } from '@reduxjs/toolkit/query/react';

import customBaseQuery from './common/customBaseQuery';

import {
    RULES_OPTION_PARAMETER_TYPE_DICTIONARY,
    RULES_OPTION_PARAMETER_TYPE_OBJECT,
    RULES_OPTION_PARAMETER_TYPE_VOCABULARY,
} from '../../constants';
import { RuleHistory, RuleModel, RuleParamModel } from '../../models/RulesModel';

type GetHistoryResponse = {
    count: number;
    items: RuleHistory[];
};
// todo сортировка пока на бекенде не будет добавлено поле order
function compareParams(a: RuleParamModel, b: RuleParamModel): number {
    if (a.key === 'period' && b.key === 'periodType') {
        return -1;
    } else if (a.key === 'periodType' && b.key === 'period') {
        return 1;
    } else {
        return 0;
    }
}
export const rulesApi = createApi({
    reducerPath: 'rulesApi',
    baseQuery: customBaseQuery,
    tagTypes: ['Rule', 'Rules'],
    endpoints: (builder) => ({
        getRules: builder.query<RuleModel[], Partial<QueryOptions<RuleModel>>>({
            query(queryData) {
                const queryString = buildQuery(queryData);
                return {
                    url: `process/rules${queryString}`,
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json; charset=windows-1251' },
                };
            },
            providesTags: ['Rules'],
        }),
        getRule: builder.query<RuleModel, string | number | undefined>({
            query(id) {
                return {
                    url: `process/rules/${id}`,
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json; charset=windows-1251' },
                };
            },
            providesTags: ['Rule'],
            transformResponse: (response: RuleModel) => {
                const catalog: RuleParamModel[] = [];
                const params: RuleParamModel[] = [];
                (response?.params as RuleParamModel[])
                    ?.filter((param) => !param.isSystem)
                    .forEach((param) => {
                        if (
                            param.type?.code === RULES_OPTION_PARAMETER_TYPE_DICTIONARY ||
                            param.type?.code === RULES_OPTION_PARAMETER_TYPE_VOCABULARY
                        ) {
                            catalog.push(param);
                        } else {
                            params.push(param);
                        }
                    });
                // todo костыль, убрать после доработки api
                params.forEach((param) => {
                    if (param.type?.code === RULES_OPTION_PARAMETER_TYPE_OBJECT && typeof param.value === 'string') {
                        param.value = JSON.parse((param.value as string).replace(/:""/g, ':["').replace(/""}/g, '"]}'));
                    }
                });

                response.params = params.sort(compareParams);
                response.catalog = catalog;

                return response;
            },
        }),
        createRule: builder.mutation<void, RuleModel>({
            query(data) {
                return {
                    url: `process/rules`,
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['Rules'],
        }),
        updateRule: builder.mutation<void, RuleModel>({
            query(data) {
                return {
                    url: `process/rules/${data.id}`,
                    method: 'PUT',
                    body: data,
                };
            },
            invalidatesTags: ['Rule', 'Rules'],
        }),
        deleteRule: builder.mutation<void, number>({
            query(id) {
                return {
                    url: `process/rules/${id}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['Rule'],
        }),
        getRuleHistory: builder.query<GetHistoryResponse, Partial<QueryOptions<RuleHistory>>>({
            query(queryData) {
                const queryString = buildQuery(queryData);
                return {
                    url: `process/rules/history${queryString}`,
                    method: 'GET',
                };
            },
            transformResponse: (dictionary: GetHistoryResponse) => {
                dictionary.items.forEach((item) => {
                    if (
                        typeof item.newValue === 'object' &&
                        !Array.isArray(item.newValue.params) &&
                        item.newValue.params?.description === 'Пов’язані особи'
                    ) {
                        (item.newValue.params.value as string[]).sort((a, b) =>
                            a.localeCompare(b, undefined, { numeric: true }),
                        );
                    }
                    if (
                        typeof item.prevValue === 'object' &&
                        !Array.isArray(item.prevValue.params) &&
                        item.prevValue.params?.description === 'Пов’язані особи'
                    ) {
                        (item.prevValue.params.value as string[]).sort((a, b) =>
                            a.localeCompare(b, undefined, { numeric: true }),
                        );
                    }
                });
                return dictionary;
            },
            providesTags: ['Rule'],
        }),
    }),
});

export const { useGetRulesQuery, useGetRuleQuery, useUpdateRuleMutation, useGetRuleHistoryQuery } = rulesApi;
