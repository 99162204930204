import { FC, ReactNode } from 'react';

import { Col, Divider, Row } from 'antd';

import { RULES_OPTIONS_TYPE_VIEW, RULES_OPTIONS_TYPE_EDIT } from '../../../constants/Rules';
import { OptionsType } from '../../../models/RulesModel';

interface DataFieldProps {
    describe?: string;
    value?: string | number;
    colSpanDescribe?: number;
    colSpanValue?: number;
    colSpanSpace?: number;
    optionType: OptionsType;
    children?: ReactNode;
}

const DataField: FC<DataFieldProps> = ({
    describe,
    value,
    colSpanDescribe = 4,
    colSpanValue = 19,
    colSpanSpace = 1,
    optionType,
    children,
}) => {
    return (
        <div>
            <Row className="rules-block__options_row">
                <Col span={colSpanSpace} />
                <Col span={colSpanDescribe} className="rules-block__options_describe">
                    {describe}
                </Col>
                <Col span={colSpanValue} className="rules-block__options_value">
                    {optionType === RULES_OPTIONS_TYPE_VIEW && value}
                    {optionType === RULES_OPTIONS_TYPE_EDIT && children}
                </Col>
            </Row>
            <Divider className="rules-block__options_divider" />
        </div>
    );
};

export default DataField;
