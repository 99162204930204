import { FilterValue } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { Filter } from 'odata-query';

import getContainFilterValue from './getContainFilterValue';

import { DictionaryColumnsTypes } from '../constants/enums';
import { ColumnModel } from '../models/DictionaryModel';

export const makeFilterQuery = (data: Record<string, FilterValue | null>, columns: ColumnModel[]): Filter[] => {
    let equalFilters = [];
    let containFilters = [];

    for (const key in data) {
        if (data[key] !== null) {
            const column = columns.find((column) => column.colOrmName === key);
            if (column) {
                switch (column.type.code) {
                    case DictionaryColumnsTypes.number:
                    case DictionaryColumnsTypes.string:
                        containFilters.push({
                            ['tolower(' + [key] + ')']: getContainFilterValue(data[key]?.[0] as string),
                        });
                        break;
                    case DictionaryColumnsTypes.date:
                        equalFilters.push({
                            [key]: {
                                ge: {
                                    type: 'raw',
                                    value: dayjs(data[key]?.[0] as string)
                                        .startOf('day')
                                        .toISOString(),
                                },
                                le: {
                                    type: 'raw',
                                    value: dayjs(data[key]?.[0] as string)
                                        .endOf('day')
                                        .toISOString(),
                                },
                            },
                        });
                        break;
                    case DictionaryColumnsTypes.boolean:
                        equalFilters.push({ [key]: data[key]?.[0] === '' ? null : data[key]?.[0] });
                        break;
                    case DictionaryColumnsTypes.dictionary:
                        equalFilters.push({ [key]: { description: getContainFilterValue(data[key]?.[0] as string) } });
                        break;
                    default:
                        break;
                }
            }
        }
    }

    return [...equalFilters, ...containFilters];
};

export const filterForSearch = (columns: ColumnModel[], value: string) => {
    const searchFields = ['cardNumber', 'clientId', 'merchantId', 'groupCode', 'description', 'code'];
    let filter: any[] = [{ or: [] }];
    searchFields.forEach((key) => {
        if (columns.some((column) => column.colOrmName === key) && value) {
            filter[0].or.push({ ['tolower(' + [key] + ')']: getContainFilterValue(value) });
        }
    });

    return filter;
};

export const getExpand = (columns: ColumnModel[]) => {
    const expand = columns
        .filter((column) => column.type.code === DictionaryColumnsTypes.dictionary)
        .map((column) => column.colOrmName)
        .join(',');
    return expand ? expand : '';
};
