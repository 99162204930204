import { ALERT_PARAMETER } from '../constants';
import { getContainFilterValue } from '../helpers';
import dayjs from 'dayjs'

export const getAlertsFilters = (filters: any) => {
    let equalFilters = [];
    let containFilters = [];
    for (let key in filters) {
        if (filters[key] !== null) {
            switch (key) {
                case ALERT_PARAMETER.id:
                case ALERT_PARAMETER.ruleDescription.join('/'):
                    containFilters.push({
                        or: filters[key].map((item: string) => ({
                            [`tolower(${key})`]: getContainFilterValue(item),
                        })),
                    });
                    break;
                case ALERT_PARAMETER.createdDate:
                    equalFilters.push({
                        [key]: {
                            ge: {
                                type: 'raw',
                                value: dayjs(filters[key][0]).startOf('day').toISOString(),
                            },
                            le: {
                                type: 'raw',
                                value: dayjs(filters[key][0]).endOf('day').toISOString(),
                            },
                        },
                    });
                    break;
                case ALERT_PARAMETER.assignedUserId.join('/'):
                case ALERT_PARAMETER.assignedUserEmail.join('/'):
                    equalFilters.push({
                        or: filters[key].map((item: string) => ({
                            [key]: `${item}`,
                        })),
                    });
                    break;
                default:
                    break;
            }
        }
    }
    return [...containFilters, ...equalFilters];
};
