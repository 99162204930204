import { RuleModel } from '../models/RulesModel';

export const filterRules = (type: string, rules?: RuleModel[]) =>
    rules
        ? rules
              .filter((rule) => rule.type.code === type)
              .sort((a, b) => {
                  const codeA = a.code.replace('(', '').replace(')', '');
                  const codeB = b.code.replace('(', '').replace(')', '');
                  if (codeA.length < codeB.length) return -1;
                  return +codeA < +codeB ? -1 : +codeA > +codeB ? 1 : 0;
              })
        : [];
