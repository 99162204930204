import { combineReducers } from '@reduxjs/toolkit';
import { authApi } from './api/authApi';
import authReducer from './features/authSlice';
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';
import { rolesApi } from './api/rolesApi';
import { userApi } from './api/userApi';
import { alertsApi } from './api/alertsApi';
import { dictionaryApi } from './api/dictionaryApi';
import { rulesApi } from './api/rulesApi';
import { transactionsApi } from './api/transactionsApi';
import { reportsApi } from './api/reportsApi';

const authPersistConfig = {
    key: 'root_epb',
    storage,
};

const rootReducer = combineReducers({
    auth: persistReducer(authPersistConfig, authReducer),
    [authApi.reducerPath]: authApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [alertsApi.reducerPath]: alertsApi.reducer,
    [dictionaryApi.reducerPath]: dictionaryApi.reducer,
    [rulesApi.reducerPath]: rulesApi.reducer,
    [transactionsApi.reducerPath]: transactionsApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
});
export default rootReducer;
