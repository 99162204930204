export const STATUS_CODE_NEW = 'new';
export const STATUS_CODE_ASSIGNED = 'assigned';
export const STATUS_CODE_PROCESS = 'process';
export const STATUS_CODE_PROCESSED = 'processed';
export const STATUS_CODE_AGREED = 'agreed';
export const STATUS_CODE_AMENDMENT = 'amendment';
export const STATUS_CODE_CLOSED = 'closed';

export const PRIORITY_CODE_IMMEDIATELY = 'immediately';
export const PRIORITY_CODE_PRIORITY = 'priority';
export const PRIORITY_CODE_USUAL = 'usual';
export const PRIORITY_CODE_ARCHIVE = 'archive';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT_WITH_TIME = 'DD.MM.YYYY HH:mm:ss';
export const DATE_FORMAT_FOR_QUERY = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';
export const TIME_WITH_SECONDS_FORMAT = 'HH:mm:ss';
export const DATE_FORMAT_FOR_ORACLE_QUERY = 'DD-MMM-YYYY hh:mm:ss A';
export const MONEY_MASK = {
    mask: Number, // enable number mask
    scale: 2, // digits after point, 0 for integers
    signed: false, // disallow negative
    normalizeZeros: false,
    radix: ',', // fractional delimiter
    mapToRadix: [',', '.'], // symbols to process as radix
}

export const ALERT_PARAMETER = Object.freeze({
    id: 'id',
    closeDate: 'closeDate',
    controller: 'controller',
    ruleDescription: ['rule', 'description'],
    assignedUsers: 'assignedUsers',
    assignedUserId: ['assignedUsers', 'id'],
    assignedUserEmail: ['assignedUsers', 'email'],
    createdDate: 'createdDate',
    createdUser: 'createdUser',
    updatedDate: 'updatedDate',
    updatedUser: 'updatedUser',
});
export const SESSION_STORAGE_KEY = 'alertTableSize';
