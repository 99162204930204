import customBaseQuery from './common/customBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
    DeleteQueryType,
    DictionaryHistory,
    DictionaryModel,
    FormValuesType,
    MerchantsDictionary,
    RecordModel,
} from '../../models/DictionaryModel';
import { StatusCatalog } from '../../models/Catalog';
import { UserHistoryStatus } from '../../models/UserModel';
import { RuleHistory, ruleVocabulary } from '../../models/RulesModel';
import { mutateNullToEmptyString } from '../../helpers/mutateObjectParams';
import buildQuery, { QueryOptions } from 'odata-query';
import { addIndexToRecord } from '../../helpers/addIndexToRecord';

type GetHistoryResponse = {
    count: number;
    items: RuleHistory[];
};

type getDictionaryResponse = {
    count: number;
    items: RecordModel[];
};

type getDictionaryRequest = { dictionaryName: string; queryData?: Partial<QueryOptions<RecordModel>> };

export const dictionaryApi = createApi({
    reducerPath: 'dictionaryApi',
    baseQuery: customBaseQuery,
    tagTypes: ['Dictionary'],
    endpoints: (builder) => ({
        getDictionary: builder.query<getDictionaryResponse, getDictionaryRequest>({
            query({ dictionaryName, queryData }) {
                return {
                    url: `process/dictionary/${dictionaryName}${buildQuery(queryData)}`,
                    method: 'GET',
                };
            },
            transformResponse: (res: getDictionaryResponse, _meta, arg) => ({
                ...res,
                items: res?.items?.map((item, index) =>
                    addIndexToRecord(mutateNullToEmptyString(item), index, arg.queryData?.skip),
                ),
            }),
            providesTags: ['Dictionary'],
        }),
        getDictionaries: builder.query<DictionaryModel[], void>({
            query() {
                return {
                    url: `process/dictionary`,
                    method: 'GET',
                };
            },
        }),
        updateRecordInDictionary: builder.mutation<DictionaryModel, FormValuesType>({
            query(data) {
                const { dict_name, dict_description, ...record } = data;
                return {
                    url: `process/dictionary/${dict_name}`,
                    method: 'PUT',
                    body: record,
                };
            },
            invalidatesTags: ['Dictionary'],
        }),
        addRecordToDictionary: builder.mutation<RecordModel, FormValuesType>({
            query(data) {
                const name = data.dict_name;
                const record = { ...data };
                delete record.dict_name;
                delete record.dict_description;
                return {
                    url: `process/dictionary/${name}`,
                    method: 'POST',
                    body: record,
                };
            },
            invalidatesTags: ['Dictionary'],
        }),
        deleteByIds: builder.mutation<RecordModel, DeleteQueryType>({
            query(data) {
                return {
                    url: `process/dictionary/${data.dict_name}`,
                    method: 'DELETE',
                    body: data.ids,
                };
            },
            invalidatesTags: ['Dictionary'],
        }),
        getStatuses: builder.query<StatusCatalog[], void>({
            query() {
                return {
                    url: `process/dictionary/dict_alert_statuses`,
                    method: 'GET',
                };
            },
        }),
        getUserHistoryStatuses: builder.query<UserHistoryStatus[], void>({
            query() {
                return {
                    url: `process/dictionary/dict_user_statuses`,
                    method: 'GET',
                };
            },
        }),
        getRuleRiskLevels: builder.query<ruleVocabulary[], void>({
            query() {
                return {
                    url: `process/dictionary/dict_rule_risk_levels`,
                    method: 'GET',
                };
            },
        }),
        getRuleInterval: builder.query<ruleVocabulary[], void>({
            query() {
                return {
                    url: `process/dictionary/dict_rule_interval`,
                    method: 'GET',
                };
            },
        }),
        getMerchants: builder.query<MerchantsDictionary[], void>({
            query() {
                return {
                    url: `process/dictionary/dict_merchants`,
                    method: 'GET',
                };
            },
        }),
        getSystemDictionary: builder.query<RecordModel[], string>({
            query(dictionaryName) {
                return {
                    url: `process/dictionary/${dictionaryName}`,
                    method: 'GET',
                };
            },
            keepUnusedDataFor: 3600,
        }),
        getDictionaryHistory: builder.query<GetHistoryResponse, Partial<QueryOptions<DictionaryHistory>>>({
            query(queryData) {
                const queryString = buildQuery(queryData);
                return {
                    url: `process/dictionary/history${queryString}`,
                    method: 'GET',
                };
            },
            providesTags: ['Dictionary'],
        }),
        importCsvDictionary: builder.mutation<void, { name: string; body: FormData }>({
            query({ name, body }) {
                return {
                    url: `process/dictionary/${name}/import `,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['Dictionary'],
        }),
        updateLicense: builder.mutation<void,string>({
            query(code) {
                return {
                    url: `process/license`,
                    method: 'PUT',
                    body: {value: code}
                };
            },
        }),
    }),
});

export const {
    useGetStatusesQuery,
    useGetDictionaryQuery,
    useGetDictionariesQuery,
    useGetUserHistoryStatusesQuery,
    useDeleteByIdsMutation,
    useUpdateRecordInDictionaryMutation,
    useAddRecordToDictionaryMutation,
    useGetSystemDictionaryQuery,
    useGetDictionaryHistoryQuery,
    useImportCsvDictionaryMutation,
    useUpdateLicenseMutation,
    useGetMerchantsQuery,
} = dictionaryApi;
