import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { persistor, store } from './redux/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { PersistGate } from 'redux-persist/integration/react';
import { mainTheme } from './constants/InterfaceThemes';
import uk_UA from 'antd/locale/uk_UA';
import 'dayjs/locale/uk';
import dayjs from 'dayjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.locale('uk');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <ConfigProvider theme={mainTheme} locale={uk_UA}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </ConfigProvider>
    </React.StrictMode>,
);
